import React, { Fragment } from "react";
import { AppEnum } from "../../common/AppEnum";
import * as commonMethods from '../../common/CommonMethods';
import UploadIcon from '../../asset/logos/UploadIcon.png'
import ApprovedIcon from '../../asset/logos/ApprovedIcon.svg'
import PendingIcon from '../../asset/logos/PendingIcon.svg'
import WaitingIcon from '../../asset/logos/WaitingIcon.svg'
import RejectedIcon from '../../asset/logos/RejectedIcon.svg'
import './RestaurantDocumentModule.css'
import { ExcelIconSvg } from "../../common/CommonSvgs";
import { Modal } from "react-bootstrap";

const RestaurantDocument = (props) => {

    const { state, preventLogout } = props;
    const { labelList, menuPdfBlob, alcoholPdfBlob, chosenDocumentConfig, businessName, interBankClabe,
        isMenuPdf, isMenuExcel, isChosenDocumentUploaded, documentFormData, isAlcoholFilePdf } = state;

    return (
        <Fragment>
            <div className='restaurant-main-container'>
                <div className="restaurant-container">
                    {
                        state.isDocumentChosen ?
                            <div className="choosen-doc-div">
                                {
                                    chosenDocumentConfig.fileCategoryUId === AppEnum.RestDocFileCategoryUId.BankStatement &&
                                    <div className='bank-statement-div'>

                                        <h2 className='document-title bank-title'>{chosenDocumentConfig.fileCategoryName}</h2>

                                        <div className="form-group">
                                            <div className="bank-input-div">

                                                <input name="businessName" id="businessName" type="text" className='form-control'
                                                    onChange={props.onChangeBusinessNameHandler} value={state.businessName} maxLength={200}
                                                    placeholder={labelList.filter(i => i.key === 'Phase1Document.BusinessName')[0]?.value}
                                                    disabled={((chosenDocumentConfig.isApproved === false && chosenDocumentConfig.uId) ||
                                                        (chosenDocumentConfig.isApproved === null && !chosenDocumentConfig.uId)) ? false : true}
                                                />
                                                {
                                                    (!businessName && businessName !== null) &&
                                                    <label className="invalid-error">
                                                        {labelList.filter(i => i.key === 'Validation.BillingFieldsAreRequired')[0]?.value}
                                                    </label>
                                                }
                                            </div>
                                        </div>

                                        <div className="form-group">
                                            <div className="bank-input-div">

                                                <input name="interbankClabe" id="interbankClabe" type='tel' onKeyDown={commonMethods.updateNumber} className='form-control'
                                                    onChange={props.onChangeInterbankClabeHandler} value={state.interBankClabe} maxLength={18}
                                                    placeholder={labelList.filter(i => i.key === 'Phase1Document.InterbankClabe')[0]?.value}
                                                    disabled={((chosenDocumentConfig.isApproved === false && chosenDocumentConfig.uId) ||
                                                        (chosenDocumentConfig.isApproved === null && !chosenDocumentConfig.uId)) ? false : true}
                                                />
                                                {
                                                    (!interBankClabe && interBankClabe !== null) &&
                                                    <label className="invalid-error">
                                                        {labelList.filter(i => i.key === 'Validation.BillingFieldsAreRequired')[0]?.value}
                                                    </label>
                                                }
                                            </div>
                                        </div>

                                        <label>{labelList.filter(i => i.key === 'Phase1Document.BankInfoTitle')[0]?.value}</label>

                                        <div className='info-list'>
                                            <li>{labelList.filter(i => i.key === 'Phase1Document.BankInfoYour')[0]?.value}
                                                <span> {labelList.filter(i => i.key === 'Phase1Document.BankInfoName')[0]?.value}</span>
                                                {labelList.filter(i => i.key === 'Phase1Document.BankInfo1')[0]?.value}
                                            </li>

                                            <li>{labelList.filter(i => i.key === 'Phase1Document.BankInfoThe')[0]?.value}
                                                <span> {labelList.filter(i => i.key === 'Phase1Document.BankInfoClabe')[0]?.value}</span>
                                                {labelList.filter(i => i.key === 'Phase1Document.BankInfo2')[0]?.value}
                                            </li>
                                        </div>

                                        <div className={(isChosenDocumentUploaded) ? 'document-image-div' : 'document-image'}>
                                            <img src={chosenDocumentConfig?.fileUrl ? chosenDocumentConfig.fileUrl :
                                                chosenDocumentConfig.logoUrl} id={chosenDocumentConfig.fileCategoryUId + 'image'} alt='uploadedImage' />
                                        </div>
                                        {
                                            (chosenDocumentConfig.isApproved === false && chosenDocumentConfig.actionNote && !isChosenDocumentUploaded) &&
                                            <label className='rejected-remark'>{labelList.filter(i => i.key === 'Phase1.Remark')[0]?.value} {chosenDocumentConfig.actionNote}</label>
                                        }
                                        {
                                            (chosenDocumentConfig.actionNote || !chosenDocumentConfig.fileUrl || chosenDocumentConfig.isApproved === false ||
                                                (chosenDocumentConfig?.expiryDateTime && (commonMethods.remainingExpiryDays(new Date(chosenDocumentConfig?.expiryDateTime), new Date()) < 14))) &&
                                            <div>
                                                {
                                                    ((chosenDocumentConfig.isApproved === false && chosenDocumentConfig.uId) ||
                                                        (chosenDocumentConfig.isApproved === null && !chosenDocumentConfig.uId)) &&
                                                    <>
                                                        <div className='upload-doc-btn-div'>

                                                            <label htmlFor={chosenDocumentConfig.fileCategoryUId} className="upload-label" >
                                                                {labelList.filter(i => i.key === 'Phase2.Upload')[0]?.value}
                                                                <img src={UploadIcon} alt='Upload' />
                                                            </label>

                                                            <input type="file" id={chosenDocumentConfig.fileCategoryUId} accept="image/png, image/jpg, image/jpeg, capture=camera"
                                                                onChange={(event) => props.uploadChoosenDocument(event)} onClick={(event) => { commonMethods.SetInputTypeFileFieldNull(event) }}
                                                            />
                                                        </div>

                                                        <div className={(isChosenDocumentUploaded && businessName && interBankClabe?.length === 18) ? 'confirm-button-div' : 'confirm-button-div disable-btn'} >
                                                            <button type='submit' onClick={() => props.saveBankStatementDocs()} >{labelList.filter(i => i.key === 'Phase1Document.Confirm')[0]?.value}</button>
                                                        </div>
                                                    </>
                                                }
                                            </div>
                                        }

                                        <div className='return-btn'>
                                            <button type='button' onClick={props.goToBackPage}>{labelList.filter(i => i.key === 'Phase1Document.Return')[0]?.value}</button>
                                        </div>
                                    </div>
                                }
                                {
                                    chosenDocumentConfig.fileCategoryUId === AppEnum.RestDocFileCategoryUId.DigitalMenu &&
                                    <div className='bank-statement-div'>

                                        <h2 className='document-title'>{chosenDocumentConfig.fileCategoryName}</h2>

                                        <label>{labelList.filter(i => i.key === 'Phase1Document.MenuTitle')[0]?.value}</label>

                                        <div className='info-list'>
                                            <li>
                                                <span> {labelList.filter(i => i.key === 'Phase1Document.MenuFileType')[0]?.value}</span>
                                                {labelList.filter(i => i.key === 'Phase1Document.MenuFileTypeInfo')[0]?.value}
                                            </li>

                                            <li>
                                                {labelList.filter(i => i.key === 'Phase1Document.MenuFileExcel')[0]?.value}
                                            </li>
                                        </div>
                                        {
                                            !isMenuPdf && !isMenuExcel &&
                                            <div className={(isChosenDocumentUploaded) ? 'document-image-div' : 'document-image'}>
                                                <img src={chosenDocumentConfig?.fileUrl ? chosenDocumentConfig.fileUrl :
                                                    chosenDocumentConfig.logoUrl} id={chosenDocumentConfig.fileCategoryUId + 'image'} alt='uploadedImage' />
                                            </div>
                                        }
                                        {
                                            isMenuPdf &&
                                            <div className="document-icon-div">
                                                <i class="fas fa-file-pdf" onClick={() => props.previewPdfDocument(menuPdfBlob, chosenDocumentConfig?.mimeType)}></i>

                                                <span onClick={() => props.previewPdfDocument(menuPdfBlob, chosenDocumentConfig?.mimeType)}>
                                                    {documentFormData?.name ? commonMethods.trimText(documentFormData.name, 30)
                                                        : commonMethods.trimText(chosenDocumentConfig?.fileName, 30)}
                                                </span>
                                            </div>
                                        }
                                        {
                                            isMenuExcel &&
                                            <div className="svg-icon-div">

                                                {ExcelIconSvg}
                                                <span>
                                                    {documentFormData?.name ? commonMethods.trimText(documentFormData.name, 30)
                                                        : commonMethods.trimText(chosenDocumentConfig.fileName, 30)}
                                                </span>
                                            </div>
                                        }
                                        {
                                            (chosenDocumentConfig.isApproved === false && chosenDocumentConfig.actionNote && !isChosenDocumentUploaded) &&
                                            <label className='rejected-remark'>{labelList.filter(i => i.key === 'Phase1.Remark')[0]?.value} {chosenDocumentConfig.actionNote}</label>
                                        }
                                        {
                                            (chosenDocumentConfig.actionNote || !chosenDocumentConfig.fileUrl || chosenDocumentConfig.isApproved === false ||
                                                (chosenDocumentConfig?.expiryDateTime && (commonMethods.remainingExpiryDays(new Date(chosenDocumentConfig?.expiryDateTime), new Date()) < 14))) &&
                                            <div>
                                                {
                                                    ((chosenDocumentConfig.isApproved === false && chosenDocumentConfig.uId) ||
                                                        (chosenDocumentConfig.isApproved === null && !chosenDocumentConfig.uId)) &&
                                                    <>
                                                        <div className='upload-doc-btn-div'>

                                                            <label htmlFor={chosenDocumentConfig.fileCategoryUId} className="upload-label" >
                                                                {labelList.filter(i => i.key === 'Phase2.Upload')[0]?.value}
                                                                <img src={UploadIcon} alt='Upload' />
                                                            </label>

                                                            <input type="file" id={chosenDocumentConfig.fileCategoryUId} accept="image/png, image/jpg, image/jpeg, application/pdf,
                                                                   application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,  capture=camera"
                                                                onChange={(event) => props.uploadChoosenDocument(event)} onClick={(event) => { commonMethods.SetInputTypeFileFieldNull(event) }}
                                                            />
                                                        </div>

                                                        <div className={isChosenDocumentUploaded ? 'confirm-button-div' : 'confirm-button-div disable-btn'} >
                                                            <button type='submit' onClick={() => props.saveChosenDocument()} >{labelList.filter(i => i.key === 'Phase1Document.Confirm')[0]?.value}</button>
                                                        </div>
                                                    </>
                                                }
                                            </div>
                                        }

                                        <div className='return-btn'>
                                            <button type='button' onClick={props.goToBackPage}>{labelList.filter(i => i.key === 'Phase1Document.Return')[0]?.value}</button>
                                        </div>
                                    </div>
                                }
                                {
                                    chosenDocumentConfig.fileCategoryUId === AppEnum.RestDocFileCategoryUId.AlcoholPermit &&
                                    <div className='bank-statement-div'>

                                        <h2 className='document-title'>{chosenDocumentConfig.fileCategoryName}</h2>

                                        <label>{labelList.filter(i => i.key === 'Phase1Document.AlcoholTitle')[0]?.value}</label>

                                        <div className='info-list'>
                                            <li>
                                                <span> {labelList.filter(i => i.key === 'Phase1Document.AlcoholFileType1')[0]?.value}</span>
                                                {labelList.filter(i => i.key === 'Phase1Document.AlcoholFileTypeOr')[0]?.value}
                                                <span> {labelList.filter(i => i.key === 'Phase1Document.AlcoholFileType2')[0]?.value} </span>
                                            </li>

                                            <li>
                                                {labelList.filter(i => i.key === 'Phase1Document.AlcoholFileTypeRead')[0]?.value}
                                            </li>
                                        </div>
                                        {
                                            !isAlcoholFilePdf &&
                                            <div className={(isChosenDocumentUploaded) ? 'document-image-div' : 'document-image'}>
                                                <img src={chosenDocumentConfig?.fileUrl ? chosenDocumentConfig.fileUrl :
                                                    chosenDocumentConfig.logoUrl} id={chosenDocumentConfig.fileCategoryUId + 'image'} alt='uploadedImage' />
                                            </div>
                                        }
                                        {
                                            isAlcoholFilePdf &&
                                            <div className="document-icon-div">
                                                <i class="fas fa-file-pdf" onClick={() => props.previewPdfDocument(alcoholPdfBlob, chosenDocumentConfig?.mimeType)}></i>

                                                <span onClick={() => props.previewPdfDocument(alcoholPdfBlob, chosenDocumentConfig?.mimeType)}>
                                                    {documentFormData?.name ? commonMethods.trimText(documentFormData.name, 30)
                                                        : commonMethods.trimText(chosenDocumentConfig.fileName, 30)}
                                                </span>
                                            </div>
                                        }
                                        {
                                            (chosenDocumentConfig.isApproved === false && chosenDocumentConfig.actionNote && !isChosenDocumentUploaded) &&
                                            <label className='rejected-remark'>{labelList.filter(i => i.key === 'Phase1.Remark')[0]?.value} {chosenDocumentConfig.actionNote}</label>
                                        }
                                        {
                                            (chosenDocumentConfig.actionNote || !chosenDocumentConfig.fileUrl || chosenDocumentConfig.isApproved === false ||
                                                (chosenDocumentConfig?.expiryDateTime && (commonMethods.remainingExpiryDays(new Date(chosenDocumentConfig?.expiryDateTime), new Date()) < 14))) &&
                                            <div>
                                                {
                                                    ((chosenDocumentConfig.isApproved === false && chosenDocumentConfig.uId) ||
                                                        (chosenDocumentConfig.isApproved === null && !chosenDocumentConfig.uId)) &&
                                                    <>
                                                        <div className='upload-doc-btn-div'>

                                                            <label htmlFor={chosenDocumentConfig.fileCategoryUId} className="upload-label" >
                                                                {labelList.filter(i => i.key === 'Phase2.Upload')[0]?.value}
                                                                <img src={UploadIcon} alt='Upload' />
                                                            </label>

                                                            <input type="file" id={chosenDocumentConfig.fileCategoryUId} accept="image/png, image/jpg, image/jpeg, application/pdf, capture=camera"
                                                                onChange={(event) => props.uploadChoosenDocument(event)} onClick={(event) => { commonMethods.SetInputTypeFileFieldNull(event) }}
                                                            />
                                                        </div>

                                                        <div className={isChosenDocumentUploaded ? 'confirm-button-div' : 'confirm-button-div disable-btn'} >
                                                            <button type='submit' onClick={() => props.saveChosenDocument()} >{labelList.filter(i => i.key === 'Phase1Document.Confirm')[0]?.value}</button>
                                                        </div>
                                                    </>
                                                }
                                            </div>
                                        }
                                        <div className='return-btn'>
                                            <button type='button' onClick={props.goToBackPage}>{labelList.filter(i => i.key === 'Phase1Document.Return')[0]?.value}</button>
                                        </div>
                                    </div>
                                }
                                {
                                    chosenDocumentConfig.fileCategoryUId === AppEnum.RestDocFileCategoryUId.RestaurantPhoto &&
                                    <div className='bank-statement-div'>

                                        <h2 className='document-title'>{chosenDocumentConfig.fileCategoryName}</h2>

                                        <label>{labelList.filter(i => i.key === 'Phase1Document.RestaurantPhotoTitle')[0]?.value}</label>

                                        <div className={(isChosenDocumentUploaded) ? 'document-image-div' : 'document-image'}>
                                            <img src={chosenDocumentConfig?.fileUrl ? chosenDocumentConfig.fileUrl :
                                                chosenDocumentConfig.logoUrl} id={chosenDocumentConfig.fileCategoryUId + 'image'} alt='uploadedImage' />
                                        </div>
                                        {
                                            (chosenDocumentConfig.isApproved === false && chosenDocumentConfig.actionNote && !isChosenDocumentUploaded) &&
                                            <label className='rejected-remark'>{labelList.filter(i => i.key === 'Phase1.Remark')[0]?.value} {chosenDocumentConfig.actionNote}</label>
                                        }
                                        {
                                            (chosenDocumentConfig.actionNote || !chosenDocumentConfig.fileUrl || chosenDocumentConfig.isApproved === false ||
                                                (chosenDocumentConfig?.expiryDateTime && (commonMethods.remainingExpiryDays(new Date(chosenDocumentConfig?.expiryDateTime), new Date()) < 14))) &&
                                            <div>
                                                {
                                                    ((chosenDocumentConfig.isApproved === false && chosenDocumentConfig.uId) ||
                                                        (chosenDocumentConfig.isApproved === null && !chosenDocumentConfig.uId)) &&
                                                    <>
                                                        <div className='upload-doc-btn-div'>

                                                            <label htmlFor={chosenDocumentConfig.fileCategoryUId} className="upload-label" >
                                                                {labelList.filter(i => i.key === 'Phase2.Upload')[0]?.value}
                                                                <img src={UploadIcon} alt='Upload' />
                                                            </label>

                                                            <input type="file" id={chosenDocumentConfig.fileCategoryUId} accept="image/png, image/jpg, image/jpeg, capture=camera"
                                                                onChange={(event) => props.uploadChoosenDocument(event)} onClick={(event) => { commonMethods.SetInputTypeFileFieldNull(event) }}
                                                            />
                                                        </div>

                                                        <div className={isChosenDocumentUploaded ? 'confirm-button-div' : 'confirm-button-div disable-btn'} >
                                                            <button type='submit' onClick={() => props.saveChosenDocument()} >{labelList.filter(i => i.key === 'Phase1Document.Confirm')[0]?.value}</button>
                                                        </div>
                                                    </>
                                                }
                                            </div>
                                        }
                                        <div className='return-btn'>
                                            <button type='button' onClick={props.goToBackPage}>{labelList.filter(i => i.key === 'Phase1Document.Return')[0]?.value}</button>
                                        </div>
                                    </div>
                                }
                                {
                                    chosenDocumentConfig.fileCategoryUId === AppEnum.RestDocFileCategoryUId.RestaurantLogo &&
                                    <div className='bank-statement-div'>

                                        <h2 className='document-title'>{chosenDocumentConfig.fileCategoryName}</h2>

                                        <label>{labelList.filter(i => i.key === 'Phase1Document.RestaurantLogo')[0]?.value}</label>

                                        <div className={(isChosenDocumentUploaded) ? 'document-image-div' : 'document-image'}>
                                            <img src={chosenDocumentConfig?.fileUrl ? chosenDocumentConfig.fileUrl :
                                                chosenDocumentConfig.logoUrl} id={chosenDocumentConfig.fileCategoryUId + 'image'} alt='uploadedImage' />
                                        </div>
                                        {
                                            (chosenDocumentConfig.isApproved === false && chosenDocumentConfig.actionNote && !isChosenDocumentUploaded) &&
                                            <label className='rejected-remark'>{labelList.filter(i => i.key === 'Phase1.Remark')[0]?.value} {chosenDocumentConfig.actionNote}</label>
                                        }
                                        {
                                            (chosenDocumentConfig.actionNote || !chosenDocumentConfig.fileUrl || chosenDocumentConfig.isApproved === false ||
                                                (chosenDocumentConfig?.expiryDateTime && (commonMethods.remainingExpiryDays(new Date(chosenDocumentConfig?.expiryDateTime), new Date()) < 14))) &&
                                            <div>

                                                {
                                                    ((chosenDocumentConfig.isApproved === false && chosenDocumentConfig.uId) ||
                                                        (chosenDocumentConfig.isApproved === null && !chosenDocumentConfig.uId)) &&
                                                    <>
                                                        <div className='upload-doc-btn-div'>

                                                            <label htmlFor={chosenDocumentConfig.fileCategoryUId} className="upload-label" >
                                                                {labelList.filter(i => i.key === 'Phase2.Upload')[0]?.value}
                                                                <img src={UploadIcon} alt='Upload' />
                                                            </label>

                                                            <input type="file" id={chosenDocumentConfig.fileCategoryUId} accept="image/png, image/jpg, image/jpeg, capture=camera"
                                                                onChange={(event) => props.uploadChoosenDocument(event)} onClick={(event) => { commonMethods.SetInputTypeFileFieldNull(event) }}
                                                            />
                                                        </div>

                                                        <div className={isChosenDocumentUploaded ? 'confirm-button-div' : 'confirm-button-div disable-btn'} >
                                                            <button type='submit' onClick={() => props.saveChosenDocument()} >{labelList.filter(i => i.key === 'Phase1Document.Confirm')[0]?.value}</button>
                                                        </div>
                                                    </>
                                                }
                                            </div>
                                        }
                                        <div className='return-btn'>
                                            <button type='button' onClick={props.goToBackPage}>{labelList.filter(i => i.key === 'Phase1Document.Return')[0]?.value}</button>
                                        </div>
                                    </div>
                                }
                            </div>
                            :
                            <div>
                                <div className='main-status-div'>

                                    <div className='status-div'>
                                        <div className="status-title">
                                            <img src={PendingIcon} alt='Pending' />
                                            <label>{labelList.filter(i => i.key === 'Phase1Document.Pending')[0]?.value}</label>
                                        </div>

                                        <div className="status-title">
                                            <img src={WaitingIcon} alt='Waiting' />
                                            <label>{labelList.filter(i => i.key === 'Phase1Document.ApprovalPending')[0]?.value}</label>
                                        </div>
                                    </div>

                                    <div className='status-div'>
                                        <div className="status-title">
                                            <img src={ApprovedIcon} alt='Approved' />
                                            <label>{labelList.filter(i => i.key === 'Phase1Document.Approved')[0]?.value}</label>
                                        </div>

                                        <div className="status-title">
                                            <img src={RejectedIcon} alt='Rejected' />
                                            <label>{labelList.filter(i => i.key === 'Phase1Document.Rejected')[0]?.value}</label>
                                        </div>
                                    </div>
                                </div>

                                <hr className='hr-horizontal' />

                                <div className="docs-list-div">

                                    <h2 className='restaurant-doc-title'>{labelList.filter(i => i.key === 'Phase1Document.RestDocumentHeader')[0]?.value}</h2>
                                    {
                                        state.restaurantDocumentList?.map((document, index) => { 
                                            return (
                                                <React.Fragment key={index}>

                                                    <div className={`${((document.uId && !document.isApproved && document.actionNote) ||
                                                        (document.isApproved && commonMethods.remainingExpiryDays(new Date(document.expiryDateTime), new Date()) < 14))
                                                        ? 'rejected' : document.isApproved ? 'approved' :
                                                            (document.uId && document.isApproved === null) ? 'waiting' : ''} ${'doc-info-section'}`}
                                                        onClick={() => props.openRestaurantDocument(document)}>

                                                        <label>{document.fileCategoryName} <i className="fas fa-chevron-right chevron-right"></i></label>
                                                    </div>
                                                </React.Fragment>
                                            )
                                        })
                                    }
                                </div>

                                <div className='logout-btn-div'>

                                    <div className='logout-button-div'  >
                                        <button type='button' onClick={() => props.logout()}>{labelList.filter(i => i.key === 'Phase1Document.LogoutButton')[0]?.value}</button>
                                    </div>

                                    <div className={(state.isBankStatementUploaded && state.isMenuUploaded && state.isAlcoholDocUploaded && state.isRestaurantDocUploaded
                                        && state.isRestaurantLogoUploaded) ? 'final-button-div' : 'final-button-div disable-btn'} >

                                        <button type='button' onClick={() => props.finalizeUploadingDocs()}>{labelList.filter(i => i.key === 'Phase1Document.Finalize')[0]?.value}</button>
                                    </div>

                                </div>
                            </div>
                    }
                    {
                        <Modal className="modal-info" show={state.sureToLogout} onHide={preventLogout}>
                            <Modal.Body>
                                <label className="logout-label">{labelList.filter(i => i.key === 'Phase1Document.LogoutModal1')[0]?.value} </label>
                                <label className="logout-content-label">{labelList.filter(i => i.key === 'Phase1Document.LogoutModal2')[0]?.value}</label>
                                <div className='logout-bttn-div'>
                                    <button type='button' className='no-btn' onClick={preventLogout} >
                                        {labelList.filter(i => i.key === 'Phase1Document.No')[0]?.value}</button>
                                    <button type='button' className='yes-btn' onClick={props.confirmLogout} >
                                        {labelList.filter(i => i.key === 'Phase1Document.Yes')[0]?.value}</button>
                                </div>
                            </Modal.Body>
                        </Modal>
                    }
                </div>
            </div>
        </Fragment >
    )

}

export default RestaurantDocument;