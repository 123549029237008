import { axios } from "../../../core/axios.interceptor";

export async function getAttachmentConfig(config, languageCode) {
    const response = await axios.get(`AttachmentConfiguration/get/${config.countryUId}/
    ${config.attachmentTypeCD}/${languageCode}`);
    return response.data;
}

export async function getUserProfile() {
    const response =  await axios.get(`User/profile`);
    return response.data;
}

// it retrieves seller profile
export async function getSellerProfile() {
    const response =  await axios.get(`seller/profile`);
    return response.data;
}