import React from 'react';
import { ErrorMessage } from 'formik';
import Select from "react-select";

const ReactstarpFormikInput = (
    {
        field: { ...fields },
        form: { touched, errors, setFieldValue },
        ...props
    }) => (
    <React.Fragment>
        <Select {...props} {...fields} isMulti
            className={'custom-multi-select' + (touched[fields.name] && errors[fields.name] ? ' is-invalid' : '')}
            style={{ backgroundImage: 'none', }}
            isSearchable={false}
            value={props.value}
            onChange={(event) => {
                setFieldValue(fields.name, event);
                props.onChange(event, fields.name);
            }}>
            {props.options.map((item, index) => <option key={index} value={item?.value} >{item.label}</option>)}
        </Select>
        <ErrorMessage name={[fields.name]} component="div" className="invalid-feedback" />
    </React.Fragment>
);

export default ReactstarpFormikInput;