import React, { useEffect, useRef, useState } from "react";
import restaurantState, { formValidationSchema } from './RestaurantInfoState';
import * as documentService from '../../service/auth/document/Document.Service';
import { parsePhoneNumber } from 'react-phone-number-input';
import { AppEnum } from "../../common/AppEnum";
import { useLocation, useNavigate } from "react-router-dom";
import RestaurantInfo from "../../component/restaurant-info/RestaurantInfo";
import { geocodeByAddress, getLatLng, } from 'react-places-autocomplete';
import { toast } from "react-toastify";
import * as commonMethods from '../../common/CommonMethods';
import jwtDecode from "jwt-decode";

const RestaurantInfoContainer = () => {

    const isComponentMounted = useRef(true);
    const navigate = useNavigate()
    const location = useLocation();
    const [state, setState] = useState(restaurantState);
    const formikRef = useRef();

    // sets several necessary states during component mounting
    useEffect(() => {
        let masterApiData = JSON.parse(localStorage.getItem('languageApiLabels'));

        if (isComponentMounted.current) {
            getConfigTypeList(AppEnum.GroupNameCD.SERVICE_TYPE);
            getConfigTypeList(AppEnum.GroupNameCD.TAX_REGIME);
            getConfigTypeList(AppEnum.GroupNameCD.SELLER_LEGAL_REPRESENTATIVE);

            let token = localStorage.getItem('token');
            let sellerInfo = jwtDecode(token);
            setState((prevState) => {
                return {
                    ...prevState, labelList: masterApiData,
                    countryNameCode: sellerInfo?.countrycode?.toLowerCase()?.trim()
                }
            });

            if (!location.state.isSellerRejected)
                getTaxRegimeFileConfigs(AppEnum.FileRelationTableIds.TaxRegimeLegal);

            if (!location.state) {
                navigate('/seller/layout');
            }
            else {
                let cellphoneNumber;
                let countryCode = '';
                let countryList = [];
                let countryUId = '';

                // it sets the previous restaurant info 
                if (location.state.isSellerRejected) {
                    let sellerResponse = location?.state?.sellerResponse;
                    let restaurantInfoObj = state.restaurantInfo;

                    let isAddressRejected = sellerResponse.addressApprovalCD;
                    isAddressRejected = isAddressRejected ? isAddressRejected === AppEnum.FoodApprovalStatusCD.Rejected : false
                    let adressRemark = isAddressRejected ? sellerResponse.adressRemark : null;
                    let remarks = location.state.isSellerProfileRejected ? sellerResponse.remarks : null;

                    cellphoneNumber = parsePhoneNumber(sellerResponse.mobileNo);
                    countryUId = sellerResponse.countryUId
                    restaurantInfoObj.restaurantMobileNo = cellphoneNumber.nationalNumber;
                    restaurantInfoObj.restaurantName = sellerResponse.restaurantName;
                    restaurantInfoObj.zipCode = sellerResponse.zipCode;
                    restaurantInfoObj.configTaxRegimeNo = sellerResponse.configTaxRegimeNo;
                    restaurantInfoObj.businessName = sellerResponse.businessName;
                    restaurantInfoObj.rfc = sellerResponse.rfc;
                    restaurantInfoObj.legalRepresentativeFirstName = sellerResponse.legalRepresentativeFirstName;
                    restaurantInfoObj.legalRepresentativeLastName = sellerResponse.legalRepresentativeLastName;
                    restaurantInfoObj.legalRepresentativenConfigNo = sellerResponse.legalRepresentativenConfigNo;
                    restaurantInfoObj.legalRepresentativeNumber = sellerResponse.legalRepresentativeNumber;

                    let fileCategories = [];
                    sellerResponse.profileDocuments.forEach((fileCategory) => {
                        fileCategories.push(fileCategory);
                        if (fileCategory.mimeType === "application/pdf") {
                            if (fileCategory.fileCategoryUId === AppEnum.RestaurantAttachmentUId.IneFrontPhotography) {
                                setState((prevState) => {
                                    return { ...prevState, isFrontInePdf: true, ineFrontPdfBlob: fileCategory.fileUrl }
                                });
                            }
                            if (fileCategory.fileCategoryUId === AppEnum.RestaurantAttachmentUId.IneBackPhotography) {
                                setState((prevState) => {
                                    return { ...prevState, isBackInePdf: true, ineBackPdfBlob: fileCategory.fileUrl }
                                });
                            }
                            if (fileCategory.fileCategoryUId === AppEnum.RestaurantAttachmentUId.TaxIdentificationCard) {
                                setState((prevState) => {
                                    return { ...prevState, isTaxIdPdf: true, taxIdPdfBlob: fileCategory.fileUrl }
                                });
                            }
                            if (fileCategory.fileCategoryUId === AppEnum.RestaurantAttachmentUId.PowerofAttorney) {
                                setState((prevState) => {
                                    return { ...prevState, isAttorneyPdf: true, attorneypdfBlob: fileCategory.fileUrl }
                                });
                            }
                            if (fileCategory.fileCategoryUId === AppEnum.RestaurantAttachmentUId.ArticlesofIncorporation) {
                                setState((prevState) => {
                                    return { ...prevState, isConstitutiveActPdf: true, constitutivePdfBlob: fileCategory.fileUrl }
                                });
                            }
                        }
                    })

                    if (String(sellerResponse.configTaxRegimeNo) === AppEnum.ConfigTaxRegimeNo.TaxRegimePersonal) {

                        setState((prevState) => {
                            return {
                                ...prevState, isPersonalTaxRegime: true, taxRegimePersonalConfig: [...fileCategories], isFrontIneUploaded: true,
                                isBackIneUploaded: true, isTaxIdUploaded: true,
                            }
                        });
                        let deletedDocumentList = sellerResponse.profileDocuments.filter(i => i.uId === null);

                        if (deletedDocumentList?.length > 0)
                            deletedDocumentList.forEach((item) => {
                                if (item.fileCategoryUId === AppEnum.RestaurantAttachmentUId.IneFrontPhotography)
                                    setState((prevState) => { return { ...prevState, isFrontIneUploaded: false, } });

                                if (item.fileCategoryUId === AppEnum.RestaurantAttachmentUId.IneBackPhotography)
                                    setState((prevState) => { return { ...prevState, isBackIneUploaded: false, } });

                                if (item.fileCategoryUId === AppEnum.RestaurantAttachmentUId.TaxIdentificationCard)
                                    setState((prevState) => { return { ...prevState, isTaxIdUploaded: false, } });
                            })
                    }

                    if (String(sellerResponse.configTaxRegimeNo) === AppEnum.ConfigTaxRegimeNo.TaxRegimeLegal) {

                        setState((prevState) => {
                            return {
                                ...prevState, isLegalTaxRegime: true, taxRegimeLegalConfig: [...fileCategories], isFrontIneUploaded: true,
                                isBackIneUploaded: true, isTaxIdUploaded: true, isAttorneyUploaded: true, isConstitutiveActUploaded: true,
                            }
                        });

                        let deletedDocumentList = sellerResponse.profileDocuments.filter(i => i.uId === null);

                        if (deletedDocumentList?.length > 0)
                            deletedDocumentList.forEach((item) => {
                                if (item.fileCategoryUId === AppEnum.RestaurantAttachmentUId.IneFrontPhotography)
                                    setState((prevState) => { return { ...prevState, isFrontIneUploaded: false, } });

                                if (item.fileCategoryUId === AppEnum.RestaurantAttachmentUId.IneBackPhotography)
                                    setState((prevState) => { return { ...prevState, isBackIneUploaded: false, } });

                                if (item.fileCategoryUId === AppEnum.RestaurantAttachmentUId.TaxIdentificationCard)
                                    setState((prevState) => { return { ...prevState, isTaxIdUploaded: false, } });

                                if (item.fileCategoryUId === AppEnum.RestaurantAttachmentUId.PowerofAttorney)
                                    setState((prevState) => { return { ...prevState, isAttorneyUploaded: false, } });

                                if (item.fileCategoryUId === AppEnum.RestaurantAttachmentUId.ArticlesofIncorporation)
                                    setState((prevState) => { return { ...prevState, isConstitutiveActUploaded: false, } });
                            })
                    }

                    let latLng = {};
                    latLng.lat = sellerResponse.locationLatitude;
                    latLng.lng = sellerResponse.locationLongitude;

                    let serviceTypes = [];
                    sellerResponse.services.forEach(element => {
                        let serviceType = {
                            value: element.serviceTypeCD,
                            cd: element.serviceTypeCD,
                            label: element.serviceTypeDescription
                        }
                        serviceTypes.push(serviceType);
                    });

                    setState((prevState) => {
                        return {
                            ...prevState,
                            restaurantInfo: restaurantInfoObj, restaurantAddress: sellerResponse.address, adressRemark: adressRemark,
                            latLng: latLng, serviceTypeCDs: [...serviceTypes], addressApprovalCD: isAddressRejected ? false : true,
                            remarks: remarks, isSellerProfileRejected: remarks ? true : false
                        }
                    });
                }
                else {
                    cellphoneNumber = parsePhoneNumber(location.state.mobileNo);
                    countryUId = location.state.countryUId;
                    resetFormikFieldState();
                }
                countryCode = '+' + cellphoneNumber.countryCallingCode;
                countryList = [{ label: countryCode, value: countryUId }]

                setFormValidations(masterApiData, countryCode);
                setState((prevState) => {
                    return { ...prevState, countryList: countryList, countryCode: countryCode }
                });
            }
        }
        window.scrollTo(0, 0);
        return () => { isComponentMounted.current = false; }
    }, [])

    // it sets the form validations
    const setFormValidations = (masterApiData, countryCode) => {
        let mobileValidation = commonMethods.getMinMaxMobileLength(masterApiData, countryCode);
        let validationSchema = formValidationSchema(masterApiData, mobileValidation.minMobileLength, mobileValidation.maxMobileLength);

        setState((prevState) => {
            return {
                ...prevState, mobileNoMaxLength: mobileValidation.maxMobileLength,
                formValidationSchema: validationSchema
            }
        });
    }

    // it retrieves the diff config dropdown type list 
    const getConfigTypeList = (GroupNameCD) => {

        documentService.getConfigDataByGroupName(GroupNameCD)
            .then((response) => {

                if (response) {
                    response.map(type => {
                        type.value = type.cdNo;
                        type.label = type.description;
                    })
                    if (GroupNameCD === AppEnum.GroupNameCD.SERVICE_TYPE) {
                        response.map(type => {
                            type.value = type.cd;
                            type.label = type.description;
                        })
                        setState((prevState) => { return { ...prevState, serviceTypeList: response } });
                    }
                    if (GroupNameCD === AppEnum.GroupNameCD.TAX_REGIME)
                        setState((prevState) => { return { ...prevState, taxRegimeList: [{ value: '', label: prevState.labelList.filter(i => i.key === 'Phase2.TaxRegime')[0]?.value }, ...response] } });
                    if (GroupNameCD === AppEnum.GroupNameCD.SELLER_LEGAL_REPRESENTATIVE) {
                        setState((prevState) => { return { ...prevState, legalIneList: [{ value: '', label: prevState.labelList.filter(i => i.key === 'Phase2.PleaseSelectLabel')[0]?.value }, ...response] } });
                    }
                }
            })
    }

    // it retrieves the tax regime documents 
    const getTaxRegimeFileConfigs = (fileRelationTableId) => {

        documentService.getFileCategories(fileRelationTableId).then((response) => {
            if (response) {

                let taxRegimePersonalConfig = response.filter(config =>
                    config.uId === AppEnum.RestaurantAttachmentUId.IneFrontPhotography ||
                    config.uId === AppEnum.RestaurantAttachmentUId.IneBackPhotography ||
                    config.uId === AppEnum.RestaurantAttachmentUId.TaxIdentificationCard)

                setState((prevState) => { return { ...prevState, taxRegimeLegalConfig: response, taxRegimePersonalConfig: taxRegimePersonalConfig } });
            }
        })
    }

    // it checks if email exists or not then renders the password fields 
    const saveRestaurantInfo = (restaurantInfo) => {

        // checking if all the documents are uploaded or not
        let isFormValid = state.isPersonalTaxRegime ? (state.serviceTypeCDs.length > 0 && state.latLng && state.isFrontIneUploaded
            && state.isBackIneUploaded && state.isTaxIdUploaded ? true : false) :
            state.isLegalTaxRegime ? (state.serviceTypeCDs.length > 0 && state.latLng && state.isFrontIneUploaded && state.isBackIneUploaded
                && state.isTaxIdUploaded && state.isAttorneyUploaded && state.isConstitutiveActUploaded ? true : false) : false;

        if (isFormValid) {
            let sellerFormData = new FormData();
            let previousRestaurantInfo = location.state.isSellerRejected ? location.state.sellerResponse : location.state

            previousRestaurantInfo.businessName = restaurantInfo.businessName;
            previousRestaurantInfo.configTaxRegimeNo = restaurantInfo.configTaxRegimeNo;
            previousRestaurantInfo.legalRepresentativeFirstName = restaurantInfo.legalRepresentativeFirstName;
            previousRestaurantInfo.legalRepresentativeLastName = restaurantInfo.legalRepresentativeLastName;
            previousRestaurantInfo.legalRepresentativeNumber = restaurantInfo.legalRepresentativeNumber;
            previousRestaurantInfo.legalRepresentativenConfigNo = restaurantInfo.legalRepresentativenConfigNo;
            previousRestaurantInfo.restaurantMobileNo = state.countryCode + restaurantInfo.restaurantMobileNo;
            previousRestaurantInfo.restaurantName = restaurantInfo.restaurantName;
            previousRestaurantInfo.rfc = restaurantInfo.rfc;
            previousRestaurantInfo.zipCode = restaurantInfo.zipCode;

            restaurantInfo = { ...previousRestaurantInfo };
            state.fileBlobList.forEach((file) => {
                sellerFormData.append(file.fileCategoryUId, file)
            })

            let serviceTypeCD = [];
            state.serviceTypeCDs.forEach((serviceType) => {
                serviceTypeCD.push(serviceType.cd);
            })

            restaurantInfo.serviceTypeCDs = serviceTypeCD;
            restaurantInfo.fileCategories = state.fileCategories;

            restaurantInfo.locationLatitude = state.latLng.lat;
            restaurantInfo.locationLongitude = state.latLng.lng;
            restaurantInfo.address = state.restaurantAddress;

            sellerFormData.append('SellerInput', JSON.stringify(restaurantInfo));

            documentService.saveRestaurantInfo(sellerFormData)
                .then((response) => {
                    if (response) {

                        // reinitializing the rest state
                        if (formikRef.current)
                            resetFormikFieldState();

                        if (restaurantInfo.approvalStatusCD.toUpperCase() === AppEnum.FoodApprovalStatusCD.Registered)
                            navigate('/seller/restaurant-documents');
                        else
                            navigate('/seller/layout');

                        toast.success(state.labelList.filter(i => i.key === 'Validation.RestIsSaved')[0].value);
                    }
                })
        }
        else
            toast.error(state.labelList.filter(i => i.key === 'Validation.UploadAllFiles')[0].value);
    }

    // it reinitialize the formik field states
    const resetFormikFieldState = () => {
        formikRef.current.setFieldValue("restaurantMobileNo", '');
        formikRef.current.setFieldValue("restaurantName", '');
        formikRef.current.setFieldValue("zipCode", '');
        formikRef.current.setFieldValue("configTaxRegimeNo", '');
        formikRef.current.setFieldValue("businessName", '');
        formikRef.current.setFieldValue("legalRepresentativeFirstName", '');
        formikRef.current.setFieldValue("legalRepresentativeLastName", '');
        formikRef.current.setFieldValue("legalRepresentativenConfigNo", '');
        formikRef.current.setFieldValue("legalRepresentativeNumber", '');
        formikRef.current.setFieldValue("rfc", '');
    }

    // it sets the restaraunt address 
    const onChangeRestaurantAddress = (address) => {
        setState((prevState) => { return { ...prevState, restaurantAddress: address } });
    }

    // It sets the lat long of the restaraunt address when the partner selects any address
    const onSelectAddressHandler = (address) => {

        setState((prevState) => { return { ...prevState, restaurantAddress: address, adressRemark: null } });

        geocodeByAddress(address)
            .then(results => getLatLng(results[0]))
            .then(latLng => {
                setState((prevState) => { return { ...prevState, latLng: latLng } });
            })
            .catch(error => toast.error(error));
    }

    // It clears the address field
    const resetRestarauntAddressField = () => {
        setState((prevState) => { return { ...prevState, restaurantAddress: '', latLng: null } });
    }

    // sets the config objects of tax regime documents
    const uploadTaxRegimeDocument = (event, regime) => {

        const file = event.target.files[0];
        let fileType = file.name.split('.').pop();

        if (file !== undefined) {
            let allowedFileSize = regime.maxFileSizeBytes;
            // ? maxFileSizeBytes :  Number(state.labelList.filter(i => i.key === 'Validation.AllowedFileSizeSeller')[0].value) * 1000000;

            if (file.size <= (regime.maxFileSizeBytes ? regime.maxFileSizeBytes : allowedFileSize)) {
                if ((file.type === "application/pdf" || file.type === "image/jpeg" || file.type === "image/jpg" || file.type === "image/png") && fileType !== 'jfif') {
                    uploadCompressedImage(file, regime)
                }
                else
                    toast.error(state.labelList.filter(i => i.key === 'Validation.SupportedFormatLabelPdf')[0].value);
            }
            else {
                if ((file.type === "image/jpeg" || file.type === "image/jpg" || file.type === "image/png")
                    && (file.type !== "application/pdf" && fileType !== 'jfif')) {

                    commonMethods.compressUploadedFile(file, Number(state.labelList.filter(i => i.key === 'Validation.CompressedFileSize')[0]?.value))
                        .then((blobFile) => {
                            if (blobFile) {
                                var newFile = new File([blobFile], blobFile.name, { type: blobFile.type });
                                uploadCompressedImage(newFile, regime);
                            }
                        })
                }
                else {
                    if (file.type === "application/pdf")
                        toast.error(state.labelList.filter(i => i.key === 'Validation.AllowedFileSizeLabel')[0].value);
                    else
                        toast.error(state.labelList.filter(i => i.key === 'Validation.SupportedFormatLabelPdf')[0].value);
                }
            }
        }
    }

    // it uploads file when size compression is done
    const uploadCompressedImage = (file, regime) => {
        let fileCategories = state.fileCategories;
        let fileCategory = {};
        let fileCategoryUId = regime.fileCategoryUId ? regime.fileCategoryUId : regime.uId;

        fileCategory.fileType = file.type;
        fileCategory.fileName = file.name;
        fileCategory.isSelected = true;
        fileCategory.newAttachment = true;;
        fileCategory.actionNote = "";
        fileCategory.isFileStored = false;

        fileCategory.categoryName = regime.categoryName;
        fileCategory.fileRelationTableId = regime.fileRelationTableId;
        fileCategory.maxFileSizeBytes = regime.maxFileSizeBytes;
        fileCategory.tableDescription = regime.tableDescription;
        fileCategory.tableName = regime.tableName;
        fileCategory.uId = regime.uId;

        if (regime.actionNote)
            regime.actionNote = '';

        fileCategory.fileCategoryUId = fileCategoryUId;
        file.fileCategoryUId = fileCategoryUId;

        let fileBlobList = state.fileBlobList;

        if (state.isLegalTaxRegime)
            uploadLagalTaxRegimeDocument(fileCategoryUId, fileCategories, fileBlobList, file);

        if (state.isPersonalTaxRegime)
            uploadPersonalTaxRegimeDocument(fileCategoryUId, fileCategories, fileBlobList, file);

        fileBlobList.push(file);

        fileCategories.push(fileCategory);
        setState((prevState) => { return { ...prevState, fileCategories: fileCategories, fileBlobList: fileBlobList } });
    }

    // It sets legal tax regime file category and fileblob to the state
    const uploadLagalTaxRegimeDocument = (fileCategoryUId, fileCategories, fileBlobList, file) => {

        if (AppEnum.RestaurantAttachmentUId.IneFrontPhotography === fileCategoryUId) {

            if (file.type === "application/pdf") {
                setState((prevState) => { return { ...prevState, isFrontInePdf: true } });
                showUploadedFiles('INEFront', fileCategoryUId, true);
            }
            else {
                showUploadedFiles('INEFront', fileCategoryUId, false);
                setState((prevState) => { return { ...prevState, isFrontInePdf: false } });
            }
            deletePreviousUploadedFile(fileCategories, fileCategoryUId, fileBlobList);
            setState((prevState) => { return { ...prevState, isFrontIneUploaded: true } });
        }
        if (AppEnum.RestaurantAttachmentUId.IneBackPhotography === fileCategoryUId) {

            if (file.type === "application/pdf") {
                setState((prevState) => { return { ...prevState, isBackInePdf: true } });
                showUploadedFiles('INEBack', fileCategoryUId, true);
            }
            else {
                setState((prevState) => { return { ...prevState, isBackInePdf: false } });
                showUploadedFiles('INEBack', fileCategoryUId, false);
            }
            deletePreviousUploadedFile(fileCategories, fileCategoryUId, fileBlobList);
            setState((prevState) => { return { ...prevState, isBackIneUploaded: true } });
        }
        if (AppEnum.RestaurantAttachmentUId.TaxIdentificationCard === fileCategoryUId) {

            if (file.type === "application/pdf") {
                setState((prevState) => { return { ...prevState, isTaxIdPdf: true } });
                showUploadedFiles('TaxId', fileCategoryUId, true);
            }
            else {
                setState((prevState) => { return { ...prevState, isTaxIdPdf: false } });
                showUploadedFiles('TaxId', fileCategoryUId, false);
            }
            deletePreviousUploadedFile(fileCategories, fileCategoryUId, fileBlobList);
            setState((prevState) => { return { ...prevState, isTaxIdUploaded: true } });
        }
        if (AppEnum.RestaurantAttachmentUId.PowerofAttorney === fileCategoryUId) {

            if (file.type === "application/pdf") {
                setState((prevState) => { return { ...prevState, isAttorneyPdf: true } });
                showUploadedFiles('PowerOfAttorney', fileCategoryUId, true);
            }
            else {
                setState((prevState) => { return { ...prevState, isAttorneyPdf: false } });
                showUploadedFiles('PowerOfAttorney', fileCategoryUId, false);
            }
            deletePreviousUploadedFile(fileCategories, fileCategoryUId, fileBlobList);
            setState((prevState) => { return { ...prevState, isAttorneyUploaded: true } });
        }
        if (AppEnum.RestaurantAttachmentUId.ArticlesofIncorporation === fileCategoryUId) {

            if (file.type === "application/pdf") {
                setState((prevState) => { return { ...prevState, isConstitutiveActPdf: true } });
                showUploadedFiles('ConstitutiveAct', fileCategoryUId, true);
            }
            else {
                setState((prevState) => { return { ...prevState, isConstitutiveActPdf: false } });
                showUploadedFiles('ConstitutiveAct', fileCategoryUId, false);
            }
            deletePreviousUploadedFile(fileCategories, fileCategoryUId, fileBlobList);
            setState((prevState) => { return { ...prevState, isConstitutiveActUploaded: true } });
        }
    }

    // It sets personal tax regime file category and fileblob to the state
    const uploadPersonalTaxRegimeDocument = (fileCategoryUId, fileCategories, fileBlobList, file) => {

        if (AppEnum.RestaurantAttachmentUId.IneFrontPhotography === fileCategoryUId) {

            if (file.type === "application/pdf") {
                setState((prevState) => { return { ...prevState, isFrontInePdf: true } });
                showUploadedFiles('INEFront', fileCategoryUId, true);
            }
            else {
                setState((prevState) => { return { ...prevState, isFrontInePdf: false } });
                showUploadedFiles('INEFront', fileCategoryUId, false);
            }

            deletePreviousUploadedFile(fileCategories, fileCategoryUId, fileBlobList);
            setState((prevState) => { return { ...prevState, isFrontIneUploaded: true } });
        }
        if (AppEnum.RestaurantAttachmentUId.IneBackPhotography === fileCategoryUId) {

            if (file.type === "application/pdf") {
                setState((prevState) => { return { ...prevState, isBackInePdf: true } });
                showUploadedFiles('INEBack', fileCategoryUId, true);
            }
            else {
                setState((prevState) => { return { ...prevState, isBackInePdf: false } });
                showUploadedFiles('INEBack', fileCategoryUId, false);
            }

            deletePreviousUploadedFile(fileCategories, fileCategoryUId, fileBlobList);
            setState((prevState) => { return { ...prevState, isBackIneUploaded: true } });
        }
        if (AppEnum.RestaurantAttachmentUId.TaxIdentificationCard === fileCategoryUId) {

            if (file.type === "application/pdf") {
                setState((prevState) => { return { ...prevState, isTaxIdPdf: true } });
                showUploadedFiles('TaxId', fileCategoryUId, true);
            }
            else {
                setState((prevState) => { return { ...prevState, isTaxIdPdf: false } });
                showUploadedFiles('TaxId', fileCategoryUId, false);
            }

            deletePreviousUploadedFile(fileCategories, fileCategoryUId, fileBlobList);
            setState((prevState) => { return { ...prevState, isTaxIdUploaded: true } });
        }
    }

    // it displays the uploaded file on screen, stores the blob data if file type is pdf
    const showUploadedFiles = (imgElementId, fileElementId, isTypePdf) => {

        var input = document.getElementById(fileElementId);
        var fReader = new FileReader();

        if (input.files[0])
            fReader.readAsDataURL(input.files[0]);

        fReader.onloadend = (e) => {
            if (isTypePdf) {
                let pdfBlob = e.target.result.substring(28);

                if (AppEnum.RestaurantAttachmentUId.IneFrontPhotography === fileElementId)
                    setState((prevState) => { return { ...prevState, ineFrontPdfBlob: pdfBlob } });

                if (AppEnum.RestaurantAttachmentUId.IneBackPhotography === fileElementId)
                    setState((prevState) => { return { ...prevState, ineBackPdfBlob: pdfBlob } });

                if (AppEnum.RestaurantAttachmentUId.PowerofAttorney === fileElementId)
                    setState((prevState) => { return { ...prevState, attorneypdfBlob: pdfBlob } });

                if (AppEnum.RestaurantAttachmentUId.ArticlesofIncorporation === fileElementId)
                    setState((prevState) => { return { ...prevState, constitutivePdfBlob: pdfBlob } });

                if (AppEnum.RestaurantAttachmentUId.TaxIdentificationCard === fileElementId)
                    setState((prevState) => { return { ...prevState, taxIdPdfBlob: pdfBlob } });
            }
            else {
                var img = document.getElementById(imgElementId);
                img.src = e.target.result;
            }
        }
    }

    // it deletes the previous uploaded file category and blob list from state
    const deletePreviousUploadedFile = (fileCategories, fileCategoryUId, fileBlobList) => {

        let deleteFileCategoryIndex = fileCategories.findIndex(i => i.fileCategoryUId === fileCategoryUId);
        if (deleteFileCategoryIndex >= 0)
            fileCategories.splice(deleteFileCategoryIndex, 1);

        let deleteBlobIndex = fileBlobList.findIndex(i => i.fileCategoryUId === fileCategoryUId);
        if (deleteBlobIndex >= 0)
            fileBlobList.splice(deleteBlobIndex, 1);
    }

    // it checks that which tax regime option is choosen and then displays the corresponding regime documents
    const onChangeTaxRegimeHandler = (configTaxRegimeNo) => {

        if (configTaxRegimeNo === AppEnum.ConfigTaxRegimeNo.TaxRegimePersonal)
            setState((prevState) => {
                return {
                    ...prevState,
                    isPersonalTaxRegime: true, isLegalTaxRegime: false,
                    fileCategories: [], fileBlobList: [],
                    isFrontIneUploaded: false, isBackIneUploaded: false,
                    isTaxIdUploaded: false, isAttorneyUploaded: false, isConstitutiveActUploaded: false
                }
            });

        if (configTaxRegimeNo === AppEnum.ConfigTaxRegimeNo.TaxRegimeLegal)
            setState((prevState) => {
                return {
                    ...prevState,
                    isPersonalTaxRegime: false, isLegalTaxRegime: true,
                    fileCategories: [], fileBlobList: [],
                    isFrontIneUploaded: false, isBackIneUploaded: false,
                    isTaxIdUploaded: false, isAttorneyUploaded: false, isConstitutiveActUploaded: false
                }
            });

        if (!configTaxRegimeNo)
            setState((prevState) => {
                return {
                    ...prevState,
                    isPersonalTaxRegime: false, isLegalTaxRegime: false,
                    fileCategories: [], fileBlobList: [],
                    isFrontIneUploaded: false, isBackIneUploaded: false,
                    isTaxIdUploaded: false, isAttorneyUploaded: false, isConstitutiveActUploaded: false
                }
            });
    }

    // it selects ultiple service types 
    const onChangeServiceType = (serviceType) => {

        setState((prevState) => { return { ...prevState, serviceTypeCDs: [...serviceType] } });
    }

    // it disables the tooltip when any dropdown is open
    const onClickDropDown = () => {
        setState((prevState) => { return { ...prevState, isDropDownOpen: !prevState.isDropDownOpen } });
    }

    // it enables the tooltips when dropdown looses focus
    const onBlurDropDown = () => {

        if (state.isDropDownOpen)
            setState((prevState) => { return { ...prevState, isDropDownOpen: false } });
    }

    // It opens the uploaded pdf in a new tab
    const previewPdfDocument = (pdfBlobData) => {

        if (state.fileCategories.length === 0)
            window.open(pdfBlobData);
        else {
            let pdfInNewTab = window.open("");
            pdfInNewTab.document.write(
                "<iframe width='100%' height='100%' src='data:application/pdf;base64, " +
                encodeURI(pdfBlobData) + "'></iframe>"
            )
        }
    }

    return (
        <React.Fragment>
            <RestaurantInfo
                state={state}
                saveRestaurantInfo={saveRestaurantInfo}
                onChangeRestaurantAddress={onChangeRestaurantAddress}
                onSelectAddressHandler={onSelectAddressHandler}
                resetRestarauntAddressField={resetRestarauntAddressField}
                uploadTaxRegimeDocument={uploadTaxRegimeDocument}
                onChangeTaxRegimeHandler={onChangeTaxRegimeHandler}
                onChangeServiceType={onChangeServiceType}
                onClickDropDown={onClickDropDown}
                onBlurDropDown={onBlurDropDown}
                isSellerRejected={location?.state?.isSellerRejected}
                previewPdfDocument={previewPdfDocument}
                formikRef={formikRef}
            />
        </React.Fragment>
    )
}

export default RestaurantInfoContainer;