import { fileAxios } from "../../../core/FileAPIInterceptor";
import { axios } from "../../../core/axios.interceptor";
import { logisticAxios } from "../../../core/LogisticApiInterceptor";

// it fetches various file categories based on relationTableId
export async function getFileCategories(relationTableId) {
    const response = await fileAxios.get(`fileCategory?fileRelationTableIds=${relationTableId}`);
    return response.data;
}

// it fetches config docs 
export async function getConfigDataByGroupName(groupName) {
    const response = await axios.get(`ConfigCD/${groupName}`);
    return response.data;
}

// saves phase 2 documents
export async function savePhase2Documents(phase2FormData) {
    const response = await axios.post(`RegistrationPhase/upload-file`, phase2FormData);
    return response.data;
}

// saves restaurant info 
export async function saveRestaurantInfo(restaurantInfo) {
    const response = await axios.post(`seller/save-images-register-step-2`, restaurantInfo);
    return response.data;
}

// gets restaurant document jsx
export async function getRestaurantDocumentList() {
    const response = await logisticAxios.get(`User/get/vehicle/validate`);
    return response.data;
}

// saves restaurant documents one by one
export async function saveRestaurantDocument(restaurantDocument) {
    const response = await axios.put(`/seller/update-restaurant-document`, restaurantDocument);
    return response.data;
}

// gets bank statement data 
export async function getBankStatement() {
    const response = await axios.get(`bank/statement`);
    return response.data;
}

// saves bank statement data
export async function saveBankStatement(bankStatementModel) {
    const response = await axios.post(`bank/statement`, bankStatementModel);
    return response.data;
}

// saves restaurant documents one by one
export async function updateBankStatement(bankStatementModel) {
    const response = await axios.put(`bank/statement`, bankStatementModel);
    return response.data;
}