import React from 'react';
import { ErrorMessage } from 'formik';

// regex to stop entering numeric and special chars
const alpha = /^[a-zA-Zñáéíóúü][a-zA-Zñáéíóúü\s]*$/;

// An input type='file' component
const ReactstarpFormikInput = (
    {
        field: { ...fields },
        form: { touched, errors, setFieldValue, ...rest },
        ...props
    }) => (
    <React.Fragment>
        <div style={{ display: 'block !important', width: '100%' }}>
            <input {...props} {...fields} className={'form-control' + (touched[fields.name] && errors[fields.name] ? ' is-invalid' : '')}
                style={{ backgroundImage: 'none' }} maxLength={props.maxLength}
                // autocomplete="off"
                autoComplete='off'
                onChange={(event) => {
                    setFieldValue(fields.name, fields.name === 'curp' ? (event.target.value.toUpperCase()) :
                        ((props.id === 'name' && event.target.value) ? (alpha.test(event.target.value) ? event.target.value : fields.value) : (event.target.value)));
                    { (fields.name === 'userPwd') && props.onChange(event.target.value, fields.name); }
                }}
            ></input>
            <ErrorMessage name={[fields.name]} component="div" className="invalid-feedback" />
        </div>
    </React.Fragment>
);

export default ReactstarpFormikInput;

