import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import * as layoutService from '../service/auth/privateLayout/Layout.Service';
import { useNavigate } from 'react-router-dom';
import { AppEnum } from '../common/AppEnum';
import { removeAfterLoginDocs } from '../container/restaurant-document/RestaurantDocumentContainer';

const PrivateLayout = () => {

    const [partnerLanguageApiLabels, setPartnerLanguageApiLabels] = useState([]);
    const navigate = useNavigate();

    // sets several necessary states during component mounting
    useEffect(() => {

        let masterData = JSON.parse(localStorage.getItem('languageApiLabels'));
        setPartnerLanguageApiLabels(masterData);
        isAuthenticated();
    }, [])

    // Checks authentication
    const isAuthenticated = () => {

        const token = localStorage.getItem('token');

        if (token)
            validateSellerProfileStatus();
        else
            navigate('/login');
    }

    // if profile is under review then it routes to the login page
    const profileIsUnderReview = () => {

        localStorage.removeItem('token');
        let masterData = JSON.parse(localStorage.getItem('languageApiLabels'));
        toast.info(masterData.filter(i => i.key === 'Validation.ProfileIsUnderReview')[0]?.value);
        navigate('/login');
    }

    // This methods validates the partner status and routes to that particular page according to their status
    const validateSellerProfileStatus = () => {

        layoutService.getSellerProfile().then((response) => {
            if (response) {

                let isSellerRejected = response.profileDocuments?.some(file => file.isApproved === false) || response.addressApprovalCD === AppEnum.FoodApprovalStatusCD.Rejected
                    || response.profileDocuments?.some(file => file.uId === null);

                let documents = removeAfterLoginDocs(response.restaurantDocuments)

                let areRestaurantDocsUploaded = documents?.some(file => file.isApproved === false) || response.addressApprovalCD === AppEnum.FoodApprovalStatusCD.Rejected
                    || documents?.some(file => file.uId === null);

                let isSellerProfileRejected = (response.approvalStatusCD === AppEnum.FoodApprovalStatusCD.Rejected) ? true : false;

                if (response.approvalStatusCD === AppEnum.FoodApprovalStatusCD.Registered) {

                    navigate('/seller/restaurant-info', {
                        state: response
                    });
                }
                else if (isSellerRejected || isSellerProfileRejected) {

                    navigate('/seller/restaurant-info', {
                        state: {
                            sellerResponse: response,
                            isSellerRejected: isSellerRejected,
                            isSellerProfileRejected: isSellerProfileRejected
                        }
                    });
                }
                else if (areRestaurantDocsUploaded) {
                    navigate('/seller/restaurant-documents', {
                        state: {
                            restaurantDocuments: response?.restaurantDocuments,
                        }
                    });
                }

                else if (response.approvalStatusCD === AppEnum.FoodApprovalStatusCD.Pending)
                    profileIsUnderReview();
                else
                    profileIsUnderReview();
            }
        })
    }

    return (
        <div className='lodaing-label-layout-div'>
            loading........
        </div>
    );
}

export default PrivateLayout;

