import React from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import LoginWithMobileContainer from './container/login/LoginWithMobileContainer';
import LoginWithEmailContainer from './container/login/LoginWithEmailContainer';
import VerifyOtpContainer from './container/verify-otp/VerifyOtpContainer';
import LayoutComponent from './container/LayoutComponent';
// import Phase2RegistrationContainer from './container/phase-2-registration/Phase2RegistrationContainer';
// import Phase2Home from './component/phase-2-registration/Phase2Home';
import PersonalInfoContainer from './container/personal-data-(create-account)/PersonalInfoContainer';
import RestaurantInfoContainer from './container/restaurant-info/RestaurantInfoContainer';
import PrivateLayout from './component/PrivateLayout';
import Phase1HomeContainer from './container/phase-1-home/Phase1HomeContainer';
import ForgotPasswordContainer from './container/forgot-password/ForgotPasswordContainer';
import ResetPasswordContainer from './container/forgot-password/ResetPasswordContainer';
import RestaurantDocumentContainer from './container/restaurant-document/RestaurantDocumentContainer';

const AppRouting = () => {

    let routes = (
        <Routes>

            <Route path="/login-with-email" element={<LoginWithEmailContainer key={Date.now()} />} />
            <Route path="/login" element={<LoginWithMobileContainer key={Date.now()} />} />
            <Route path="/verify-otp" element={<VerifyOtpContainer key={Date.now()} />} />
            <Route path="/" element={<LoginWithMobileContainer />} />
            <Route path='/create-account' element={<PersonalInfoContainer key={Date.now()} />} />
            <Route path='/forgot-password' element={<ForgotPasswordContainer key={Date.now()} />} />
            <Route path='/reset-password' element={<ResetPasswordContainer key={Date.now()} />} />

            <Route path="/seller" element={<LayoutComponent key={Date.now()} />}>
                <Route path='/seller/restaurant-info' element={<RestaurantInfoContainer key={Date.now()} />} />
                <Route path='/seller/thank-you' element={<Phase1HomeContainer key={Date.now()} />} />
                <Route path="/seller/layout" element={<PrivateLayout key={Date.now()} />} />
                <Route path="/seller/restaurant-documents" element={<RestaurantDocumentContainer key={Date.now()} />} />
                {/* <Route path="/seller/phase-2/home" key={Date.now()} element={<Phase2Home />} />
                <Route path="/seller/phase-2" key={Date.now()} element={<Phase2RegistrationContainer />} /> */}
            </Route>

            <Route path="*" element={<Navigate replace to="/login" />} />

        </Routes>
    )

    return (
        <React.Fragment>
            {routes}
        </React.Fragment>
    );
}

export default AppRouting;