import React, { useEffect, useRef, useState } from "react";
import loginState, { formValidationSchema } from './LoginState';
import * as  commonService from '../../service/common/CommonService';
import * as  languageService from '../../service/common/LanguageService';
import { useNavigate } from "react-router-dom";
import * as  loginService from '../../service/auth/login/Login.Service';
import * as commonMethods from '../../common/CommonMethods';
import LoginWithEmail from "../../component/login/LoginWithEmail";
import { AppEnum } from "../../common/AppEnum";

const LoginWithEmailContainer = () => {

    const isComponentMounted = useRef(true);
    const navigate = useNavigate();
    const [state, setState] = useState(loginState);
    const formikRef = useRef();

    // sets several necessary states during component mounting
    useEffect(() => {
        let masterApiData = JSON.parse(localStorage.getItem('languageApiLabels'));
        commonMethods.removeAsyncVehicleType();
        if (isComponentMounted.current) {
            setState((prevState) => { return { ...prevState, labelList: masterApiData } })
            getLanguageList();
        }

        let validationSchema = formValidationSchema(masterApiData);
        setState((prevState) => { return { ...prevState, formValidationSchema: validationSchema } });

        window.scrollTo(0, 0);
        return () => {
            isComponentMounted.current = false;
        }
    }, [])

    // retrives the language list
    const getLanguageList = () => {

        commonService.getlanguageDropdown()
            .then(res => {

                if (res) {
                    let languageCode = localStorage.getItem('languageCode')
                    if (languageCode === AppEnum.LanguageCodeCD.mexican)
                        res.reverse();

                    setState((prevState) => { return { ...prevState, languageList: res } })
                }
            })
    }

    //This method is used to change apps language via api
    const onChangeLanguageListHandler = (languageCode) => {

        let language = '';
        localStorage.setItem("languageCode", languageCode);

        if (languageCode === AppEnum.LanguageCodeCD.mexican) {
            language = AppEnum.LanguageListCD.Spanish;
            localStorage.setItem("language", language);
        }
        else if (languageCode === AppEnum.LanguageCodeCD.us) {
            language = AppEnum.LanguageListCD.English;
            localStorage.setItem("language", language);
        }

        languageService.getLanguageLabels(language, AppEnum.LanguageApiUserInterfaceCD.WEB_FOOD_SELLER_APP)
            .then(res => {
                if (res) {
                    localStorage.setItem("languageApiLabels", JSON.stringify(res));
                    setState((prevState) => { return { ...prevState, labelList: res } });

                    if (formikRef.current) {
                        formikRef.current.resetForm();

                        let validationSchema = formValidationSchema(res);
                        setState((prevState) => { return { ...prevState, formValidationSchema: validationSchema } });
                    }
                }
            })
    }

    // Routes to the signup page
    const signUp = () => {
        navigate('/create-account');
    }

    // Routes to login with email page 
    const onChangeLoginOptionHandler = () => {
        navigate('/login')
    }

    // Retrives the otpUId and routes to the otp page
    const onLogin = (loginDetails) => {

        loginDetails.languageCode = localStorage.getItem('languageCode');
        let formData = new FormData();

        formData.append('client_secret', AppEnum.FormDataEmailCD.client_secret);
        formData.append('client_id', AppEnum.FormDataEmailCD.client_id);
        formData.append('grant_type', AppEnum.FormDataEmailCD.grant_type);
        formData.append('username', loginDetails.userName);
        formData.append('password', loginDetails.password);
        formData.append('languagecode', loginDetails.languageCode);

        loginService.login(formData)
            .then(res => {
                
                if (res.access_token) {
                    // let currentTime = new Date().getTime() / 1000 + res.expires_in;
                    // localStorage.setItem('expires_in', currentTime);

                    // setTimeout(() => {
                    //   commonMethods.getNewToken();
                    // }, (res.expires_in - 30) * 1000);

                    localStorage.setItem('token', res.access_token);
                    localStorage.setItem('refresh_token', res.refresh_token);
                    // navigate('/userDetail/layout/');
                    navigate('/seller/layout');
                    // navigate('/seller/phase-2');
                }
            });
    }

    // It routes to the forgot password page
    const routeToFogotPassword = () => {
        navigate('/forgot-password');
    }

    return (
        <React.Fragment>
            <LoginWithEmail
                state={state}
                onChangeLanguageListHandler={onChangeLanguageListHandler}
                onChangeLoginOptionHandler={onChangeLoginOptionHandler}
                signUp={signUp}
                onLogin={onLogin}
                routeToFogotPassword={routeToFogotPassword}
                formikRef={formikRef}
            />
        </React.Fragment>
    )
}

export default LoginWithEmailContainer;