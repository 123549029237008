import * as Yup from 'yup';
import { translateViaApi } from '../../common/translator';

// regular expressions for validating text fields
const regexEmail = /^(?!\.)(?!.*\.$)(?!.*?\.\.)(?!.*?\.@)([a-zA-Z0-9_\-\.]+)@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.)|(([a-zA-Z0-9\-]+\.)+))([a-zA-Z]{2,4}|[0-9]{1,3})(\]?)$/;
const regexMobileNumber = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/

// Login with email form validation schema
export const formValidationSchema = (res) => Yup.object().shape({
    userName: Yup.string()
        .matches(regexEmail, { message: res?.filter(i => i.key === 'Validation.EmailIsInvalid')[0]?.value, excludeEmptyString: false })
        .required(res?.filter(i => i.key === 'Validation.EmailIsRequired')[0]?.value),
    password: Yup.string()
        .required(res?.filter(i => i.key === 'Validation.PasswordIsRequired')[0]?.value),
});

// Login with mobile form validation schema
export const formValidationSchemaMobile = (res, minMobileLength, maxMobileLength) => Yup.object().shape({
    userName: Yup.string()
        .matches(regexMobileNumber, { message: res?.filter(i => i.key === 'Validation.MobileNumberInvalid')[0]?.value, excludeEmptyString: false })
        .required(res?.filter(i => i.key === 'Validation.MobileNumberIsRequired')[0]?.value)
        .min(minMobileLength, res?.filter(i => i.key === 'Validation.MobileNumberInvalid')[0]?.value)
        .max(maxMobileLength, res?.filter(i => i.key === 'Validation.MobileNumberInvalid')[0]?.value),
});

const loginDetails = () => {
    return {
        userName: '',
        password: '',
        languageCode: '',
        countryUId: ''
    }
}

// States
const loginState = (function states(props) {
    return {
        loginDetails: loginDetails(),
        formValidationSchema: formValidationSchema,
        formValidationSchemaMobile: formValidationSchemaMobile,
        countryList: [],
        countryCode: '',
        labelList: [],
        languageList: [],
        mobileNoMaxLength: null
    }
})()

export default loginState;