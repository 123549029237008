import { Fragment } from 'react';
import './App.css';
import 'react-toastify/dist/ReactToastify.css';
import AppContainer from './AppContainer';
import { ToastContainer } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import CustomNavigator from './container/common/custom-navigator/CustomNavigator';

// sets the navigation history object 
const CustomNavigatorSetter = () => {

  CustomNavigator.navigateHistory = useNavigate();
  return null;
};


export default function App() {
  return (
    <Fragment>
      <CustomNavigatorSetter />
      <ToastContainer />
      <AppContainer />
    </Fragment>
  );
}
