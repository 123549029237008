export class UploadFileModel {
    uId = '';
    tableUIdValue = '';
    fileCategoryUId = '';
    fileName = '';
    fileBase64String = '';
    isUploadedFromBrowser = false;
    mimeTypeOfRawBytes = '';
    rawBytes = '';
}

export class BankStatementModel {
    uId = '';
    socialReason = '';
    interbankAccountNumber = '';
    fileCategory = {
        uId: '',
        tableUIdValue: '',
        fileCategoryUId: '',
        fileName: '',
        fileBase64String: '',
        isUploadedFromBrowser: false,
        mimeTypeOfRawBytes: '',
        rawBytes: '',
    }
}

export const numericRegex = /^[0-9\b]+$/;

// States
const documentState = (function states() {
    return {
        labelList: [],
        businessName: null,
        interBankClabe: null,
        restaurantDocumentList: [],
        isDocumentChosen: false,
        chosenDocumentConfig: {},
        documentFormData: null,
        isChosenDocumentUploaded: false,
        isBankStatementUploaded: false,
        isMenuUploaded: false,
        isAlcoholDocUploaded: false,
        isRestaurantDocUploaded: false,
        isRestaurantLogoUploaded: false,
        isMenuPdf: false,
        isMenuExcel: false,
        isAlcoholFilePdf: false,
        sureToLogout: false,
        fileCategories: [],
        bankStatementData: {},
        alcoholPdfBlob: null,
        menuPdfBlob: null
    }
})()

export default documentState;