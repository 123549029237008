import React from 'react';
import { ErrorMessage } from 'formik';

// An input type='checkbox' component
const ReactstarpFormikInput = (
    {
        field: { ...fields },
        form: { touched, errors, setFieldValue, value, ...rest },
        ...props
    }) => (
    <React.Fragment>
        <input {...props} {...fields} checked={fields.value ? fields.value : false}
            className={'form-check-input' + (touched[fields.name] && errors[fields.name] ? ' is-invalid' : '')}
            onChange={(event) => {
                setFieldValue(fields.name, event.target.checked);
                props.onChange(fields.name, !fields.value);

            }} />
        <ErrorMessage name={[fields.name]} component="div" className="invalid-feedback" />
    </React.Fragment>
);

export default ReactstarpFormikInput;