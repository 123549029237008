import { tokenAxios } from "../../../core/TokenAPIInterceptor";

export async function login(loginDetail) {
    const response = await tokenAxios.post('connect/token', loginDetail);
    return response.data;
}

// it validates partner credentials 
export async function validateEmailOrMobileNo(data) {
    const response = await tokenAxios.post(`user/validate-web-signup`, data);
    return response.data;
}

// sends otp to reset the password
export async function forgotPassword(forgotPasswordDetails) {
    const response = await tokenAxios.post('user/forgot-password', forgotPasswordDetails);
    return response.data;
}

// it resets the password
export async function resetPassword(newPasswordDetails) {
    const response = await tokenAxios.post('user/reset-password', newPasswordDetails);
    return response.data;
}