const verifyOTPState = () => {
    return {
        email: '',
        userName: '',
        otp: '',
        emailOtp: '',
        mobileOtp: '',
        userPwd: '',
        isVerify: true,
        isMobileOtpError: false,
        languageCode: '',
    }
}

const loginDetails = () => {
    return {
        userName: '',
        password: ''
    }
}

// States
const states = (function states() {
    return {
        verifyOTPState: verifyOTPState(),
        loginDetails: loginDetails(),
        labelList: [],
    }
})()

export default states;