import React from 'react';
import VooyFoodLogo from '../../../asset/logos/VooyFoodLogo.svg'

import './HeaderModule.css'

const Header = () => {

    return (
        <React.Fragment>
            <div className='header-partner-div'>
                {/* <span className='vooy-food-svg'>{VooyFoodLogoSvg}</span> */}
                <img className='vooy-food-svg' src={VooyFoodLogo} alt='VooyFoodLogo'/>
            </div>
        </React.Fragment>
    )
}

export default Header;