import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import forgotPassState, { formValidationSchema } from './ForgotPasswordState';
import ForgotPassword from "../../component/forgot-password/ForgotPassword";
import { AppEnum } from "../../common/AppEnum";
import * as loginService from '../../service/auth/login/Login.Service'

const ForgotPasswordContainer = () => {

    const isComponentMounted = useRef(true);
    const navigate = useNavigate()
    const [state, setState] = useState(forgotPassState);

    // sets several necessary states during component mounting
    useEffect(() => {

        let masterApiData = JSON.parse(localStorage.getItem('languageApiLabels'));
        if (isComponentMounted.current) {
            let validationSchema = formValidationSchema(masterApiData);
            setState((prevState) => { return { ...prevState, labelList: masterApiData, formValidationSchema: validationSchema } });
        }

        window.scrollTo(0, 0);
        return () => {
            isComponentMounted.current = false;
        }
    }, [])

    // Routes to login with email page 
    const routeToLoginPage = () => {
        navigate('/login')
    }

    // it sends otp to verify and change password
    const sendOtp = (sellerDetail) => {
        
        let formData = new FormData();
        let languageCode = localStorage.getItem('languageCode');

        formData.append('username', sellerDetail.userName);
        formData.append('secureActionTypeCD', AppEnum.SecureActionTypeCD.forgotPassword);
        formData.append('linkTableCD', AppEnum.LinkTableCD.Seller);
        formData.append('languageCode', languageCode);

        loginService.forgotPassword(formData).then(res => {
            if (res) {
                navigate('/reset-password', {
                    state: { otpuId: res.uId, userName: sellerDetail.userName }
                });
            }
        })
    }

    return (
        <React.Fragment>
            <ForgotPassword
                state={state}
                routeToLoginPage={routeToLoginPage}
                sendOtp={sendOtp}
            />
        </React.Fragment>
    )
}

export default ForgotPasswordContainer;