import React from 'react';
import * as logoutService from '../../../service/auth/logout/Logout.Service';
import { useNavigate } from 'react-router-dom';
import AuthHeader from '../../../component/common/auth-header/AuthHeader';

const AuthHeaderContainer = () => {

    const navigate = useNavigate();

    // Removes the variables and routes to the login page
    const logout = () => {

        let token = localStorage.getItem('token');

        if (token) {

            let logoutConfig = {
                authToken: token,
                fcmToken: '',
            }

            logoutService.logout(logoutConfig).then((response) => {

                if (response)
                    routeToLogin();
                else
                    routeToLogin();
            })
        }
        else
            routeToLogin();
    }

    // route to the login page
    const routeToLogin = () => {
        
        localStorage.removeItem('refresh_token');
        localStorage.removeItem('token');
        navigate('/login');
    }

    return (
        <React.Fragment>
            <AuthHeader
                routeToLogin={routeToLogin}
                logout={logout}
            />
        </React.Fragment>
    )
}

export default AuthHeaderContainer;