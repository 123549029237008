import moment from 'moment';
import { compressAccurately } from 'image-conversion';
import { AppEnum } from './AppEnum';
import CustomNavigator from '../container/common/custom-navigator/CustomNavigator';
import * as loginService from '../service/auth/login/Login.Service';
import { toast } from 'react-toastify';
import jwtDecode from 'jwt-decode';

// If partner uploads the same file again then it sets its value to null so that
// alert message can be triggered again
export function SetInputTypeFileFieldNull(event) {
    event.target.value = null
}

// shows or hides the password field text
export function showHidePassword(event, code) {
    var x;

    if (code === AppEnum.TaxInfoCode.CsdPassword) {
        x = document.getElementById(AppEnum.TaxInfoCode.CsdPassword);
    }

    if (code === AppEnum.TaxInfoCode.FielPassword) {
        x = document.getElementById(AppEnum.TaxInfoCode.FielPassword);
    }

    if (x) {
        if (x.type === "password") {
            x.type = "text";
            event.target.className = event.target.className === 'fa fa-eye-slash' ? 'fa fa-eye' : 'fa fa-eye-slash';

        } else {
            x.type = "password";
            event.target.className = event.target.className === 'fa fa-eye-slash' ? 'fa fa-eye' : 'fa fa-eye-slash';
        }
    }
}

// shows or hides the password field text
export function showHidePasswordLogin(event, fieldName) {

    var y = document.getElementById(fieldName);
    if (y) {

        if (y.type === "password") {
            y.type = "text";
            event.target.className = event.target.className === 'fa fa-eye-slash' ? 'fa fa-eye' : 'fa fa-eye-slash';
        }
        else {
            y.type = "password";
            event.target.className = event.target.className === 'fa fa-eye-slash' ? 'fa fa-eye' : 'fa fa-eye-slash';
        }
    }
}

// it prevents the user from entering alphabatic values 
export function updateNumber(event) {

    if (event.keyCode === 8 || event.keyCode === 46 || event.keyCode === 9
        || event.keyCode === 37 || event.keyCode === 39 || event.keyCode === 36 || event.keyCode === 35) {
    }
    else if (event.keyCode < 48 || event.keyCode > 57) {
        if (event.keyCode < 96 || event.keyCode > 105) {
            event.preventDefault();
        }
    }
}

// This function is used to check password strength by testing with regex string
export function checkPasswordStrength(password) {

    const strongPasswordRegex = new RegExp("^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})");
    const mediumPasswordRegex = new RegExp("^(((?=.*[a-z])(?=.*[A-Z]))|((?=.*[a-z])(?=.*[0-9]))|((?=.*[A-Z])(?=.*[0-9])))(?=.{6,})");

    if (strongPasswordRegex.test(password)) {
        return {
            strength: 'Excellent',
            className: 'strong-password'
        }
    } else if (mediumPasswordRegex.test(password)) {
        return {
            strength: 'Medium',
            className: 'medium-password'
        }
    } else {
        return {
            strength: 'Low',
            className: 'low-password'
        }
    }
}

// Calculates the remaining expiry days of the uploaded document  
export function remainingExpiryDays(expiryDate) {

    var start = moment(new Date().toDateString());
    var end = moment(expiryDate);
    var diff = end.diff(start, "days")
    return diff;
}

// trims the text
export function trimText(str, length) {

    if (str?.length > length)
        return str.substring(0, length) + '...';
    else
        return str;
};

// displays the image in new tab
export function showUploadedAttachment(id) {

    var img = document.getElementById(id);

    if (img) {
        let w = window.open(img.src);
        if (!img.src.startsWith('http')) {
            var image = new Image();
            image.src = img.src;
            w.document.write(image.outerHTML);
        }
    }
}

// it compresses the uploaded file/image
export async function compressUploadedFile(file, compressUptoFileSize) {

    const compressedFile = await compressAccurately(file,
        {
            size: compressUptoFileSize,
            type: "image/jpeg",
        }
    )
        .then((compressedImage) => {
            compressedImage.name = file.name;
            // The compressedImage in the promise is a compressed Blob type (which can be treated as a File type) file;
            return compressedImage;
        })
    return compressedFile;
}

// removes vehicle type
export function removeAsyncVehicleType() {
    localStorage.removeItem('vehicleType');
}

// regex for cheking phase 2 password
export const phase2PasswordRegex = /^[a-zA-Z0-9]+$/;


// if token is expired then a new token will be retrieved by the refresh token 
export async function getNewToken() {

    let refreshToken = localStorage.getItem('refresh_token');
    if (!refreshToken) {
        CustomNavigator.navigate('/login');
    }
    else {
        let formData = new FormData();
        let token = localStorage.getItem('token');
        let decodedToken = '';
        if (token)
            decodedToken = jwtDecode(token);

        formData.append('grant_type', AppEnum.FormDataEmailCD.refresh_token);
        formData.append('client_secret', AppEnum.FormDataEmailCD.client_secret);
        formData.append('client_id', decodedToken.client_id);
        formData.append('refresh_token', refreshToken);

        localStorage.setItem('isTokenRefreshed', true);

        const newToken = await loginService.login(formData).then((refreshTokenResponse) => {

            // get new token again 
            // setTimeout(() => {
            //     getNewToken();
            // }, (refreshTokenResponse.expires_in - 30) * 1000);

            localStorage.setItem('token', refreshTokenResponse.access_token);
            localStorage.setItem('refresh_token', refreshTokenResponse.refresh_token);
            localStorage.removeItem('isTokenRefreshed');

            return true;
        })
            .catch((err) => {
                if (err) {
                    if (localStorage.getItem('token')) {
                        let partnerLanguageApiLabels = JSON.parse(localStorage.getItem('languageApiLabels'));
                        toast.error(partnerLanguageApiLabels.filter(i => i.key === 'Validation.SessionExpired')[0].value);
                    }
                    localStorage.removeItem('token');
                    localStorage.removeItem('refresh_token');
                    localStorage.removeItem('isTokenRefreshed');
                    CustomNavigator.navigate('/login');
                }
            });

        return newToken;
    }
}

// it calculates the dynamic mobile length validation
export const getMinMaxMobileLength = (masterApiData, dialCode) => {
    let minMobileLength = Number(masterApiData.filter(i => i.key === 'Validation.MobileNoMinLength')[0]?.value);
    let maxMobileLength = Number(masterApiData.filter(i => i.key === 'Validation.MobileNoMaxLength')[0]?.value);
    let countryCode = dialCode.trim().substring(1);

    minMobileLength = minMobileLength - countryCode.length;
    maxMobileLength = maxMobileLength - countryCode.length;

    return { minMobileLength: minMobileLength, maxMobileLength: maxMobileLength }
}
