import React from 'react';
import { Formik, Field, Form } from 'formik';
import './ForgotPasswordModule.css';
import Header from '../common/header/Header';
import * as commonMethods from '../../common/CommonMethods';
import ReactTooltip from 'react-tooltip';
import FormikInput from '../common/formik/FormikInput';
import Red from '../../asset/logos/Red.png';
import Yellow from '../../asset/logos/Yellow.png'
import Green from '../../asset/logos/Green.png'

const ResetPassword = (props) => {

    const { resetPassDetail, resetPassFormValidationSchema, labelList } = props.state;

    return (
        <div className='login-main-div'>
            <Formik
                initialValues={resetPassDetail}
                enableReinitialize='true'
                validationSchema={resetPassFormValidationSchema}
                innerRef={props.formikRef}
                onSubmit={fields => {
                    props.onSubmit(fields)
                }}
            >
                {(formik) => (

                    <React.Fragment >

                        <Header />
                        <div className='login-container'>

                            <h2 className='login-title'>{labelList.filter(i => i.key === 'ForgotPassword.ChangePassword')[0]?.value}</h2>
                         
                            <Form noValidate>
                                <div className={props.state.isPasswordStrength ? "pass-div margin-b-pass" : "pass-div"}>
                                    <Field name="userPwd" id="userPwd" type="password"
                                        placeholder={labelList.filter(i => i.key === 'ForgotPassword.NewPassword')[0]?.value}
                                        onChange={(event) => props.onchangePasswordHandler(event)} component={FormikInput}
                                    />
                                    <i className="fa fa-eye" onClick={(event) => commonMethods.showHidePasswordLogin(event, 'userPwd')}></i>
                                </div>

                                {
                                    props.state.isPasswordStrength &&
                                    <span className={props.state.passwordStrength.className}>
                                        {
                                            props.state.passwordStrength.strength === "Excellent" ?

                                                <div className='pass-strength-bar'>
                                                    <div className='pass-tooltip-div'>
                                                        {labelList.filter(i => i.key === 'Validation.PasswordStrengthExcellent')[0].value}
                                                        <i className="fas fa-info-circle pass-tooltip-div" data-tip data-for='excellentPassword' ></i>
                                                        <ReactTooltip className='tooltip-width' id='excellentPassword' aria-haspopup='true' type='success' place="top" multiline={true} effect="float">
                                                            {labelList.filter(i => i.key === 'Validation.PasswordStrengthExcellentToolTip')[0].value}
                                                        </ReactTooltip>
                                                    </div>
                                                    <img src={Green} alt="Excellent Password" width="100%" />
                                                </div>
                                                :
                                                props.state.passwordStrength.strength === "Medium" ?

                                                    <div className='pass-strength-bar'>
                                                        <div className='pass-tooltip-div'>
                                                            {labelList.filter(i => i.key === 'Validation.PasswordStrengthMedium')[0].value}
                                                            <i className="fas fa-info-circle pass-tooltip-div" data-tip data-for='mediumPassword' ></i>
                                                            <ReactTooltip className='tooltip-width' id='mediumPassword' aria-haspopup='true' type='info' place="top" multiline={true} effect="float">
                                                                {labelList.filter(i => i.key === 'Validation.PasswordStrengthMediumToolTip')[0].value}
                                                            </ReactTooltip>
                                                        </div>
                                                        <img src={Yellow} alt="Medium Password" width="100%" />
                                                    </div>
                                                    :
                                                    <div className='pass-strength-bar'>
                                                        <div className='pass-tooltip-div'>
                                                            {labelList.filter(i => i.key === 'Validation.PasswordStrengthLow')[0].value}
                                                            <i className="fas fa-info-circle pass-tooltip-div" data-tip data-for='lowPassword' ></i>
                                                            <ReactTooltip className='tooltip-width' id='lowPassword' aria-haspopup='true' type='error' place="top" multiline={true} effect="float">
                                                                {labelList.filter(i => i.key === 'Validation.PasswordStrengthLowToolTip')[0].value}
                                                            </ReactTooltip>
                                                        </div>
                                                        <img src={Red} alt="Low Password" width="100%" />
                                                    </div>
                                        }
                                    </span>
                                }

                                <div className="input-sub-div">
                                    <div className="pass-div">
                                        <Field name="confirmPassword" id="confirmPassword" type="password"
                                            placeholder={labelList.filter(i => i.key === 'Phase1.ConfirmPasswordLabel')[0]?.value}
                                            component={FormikInput}  />
                                        <i className="fa fa-eye" onClick={(event) => commonMethods.showHidePasswordLogin(event, 'confirmPassword')}></i>
                                    </div>
                                </div>

                                <div className="input-sub-div">
                                    <Field name="otp" id="otp" type='tel' onKeyDown={commonMethods.updateNumber} maxLength="6"
                                        placeholder={labelList.filter(i => i.key === 'ForgotPassword.EnterOTPLabel')[0]?.value}
                                        component={FormikInput}
                                    />
                                </div>

                                <div className={(formik.isValid && formik.dirty) ? 'login-button-div margin-b-btn' : 'login-button-div disable-btn margin-b-btn'}>
                                    <button type="submit" className='change-pass-btn'>
                                        {labelList.filter(i => i.key === 'ForgotPassword.ChangePassword')[0]?.value}
                                    </button>
                                </div>

                                <div className="resend-otp-div">
                                    <label onClick={props.onReSendOTP}> {labelList.filter(i => i.key === 'Login.DidNotReceiveOtp')[0]?.value}&nbsp;</label>
                                    <label className="resend-label" onClick={props.onReSendOTP}> {labelList.filter(i => i.key === 'Login.Resend')[0]?.value}</label>
                                </div>
                                
                            </Form>
                        </div>
                    </React.Fragment >
                )}
            </Formik >
        </div >
    )
}

export default ResetPassword;