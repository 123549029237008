import React from 'react';
import { Formik, Field, Form } from 'formik';
import './ForgotPasswordModule.css';
import Header from '../common/header/Header';
import FormikInput from '../common/formik/FormikInput';

const ForgotPassword = (props) => {

    const { userDetail, formValidationSchema, labelList } = props.state;

    return (
        <div className='login-main-div'>
            <Formik
                initialValues={userDetail}
                enableReinitialize='true'
                validationSchema={formValidationSchema}
                onSubmit={fields => {
                    props.sendOtp(fields)
                }}
            >
                {(formik) => (
                    <React.Fragment >

                        <Header />

                        <div className='login-container'>
                            <h2 className='login-title'>{labelList.filter(i => i.key === 'ForgotPassword.ForgotPassword')[0]?.value}</h2>
                          
                            <Form noValidate>
                                <div>

                                    <div className="form-group">
                                        <Field
                                            name="userName"
                                            id="userName"
                                            type="text"
                                            placeholder={labelList.filter(i => i.key === 'Login.EmailLabel')[0]?.value}
                                            component={FormikInput} />
                                    </div>

                                    <div >
                                        <div className={(formik.isValid && formik.dirty) ? 'login-button-div' : 'login-button-div disable-btn'}>
                                            <button type="submit">{labelList.filter(i => i.key === 'ForgotPassword.SendOtpButton')[0]?.value}
                                            </button>
                                        </div>
                                        <div className="login-link-div">
                                            {labelList.filter(i => i.key === 'Phase1.HaveAccount')[0]?.value}<span>&nbsp;
                                                <b onClick={props.routeToLoginPage}>{labelList.filter(i => i.key === 'Login.LoginButton')[0]?.value} </b></span>
                                        </div>
                                    </div>

                                </div>
                            </Form>
                            
                        </div>
                    </React.Fragment >
                )}
            </Formik >
        </div >
    )
}

export default ForgotPassword;