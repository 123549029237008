import React from 'react';
import { Outlet } from 'react-router-dom';
import AuthHeaderContainer from './common/auth-header/AuthHeaderContainer';

const LayoutComponent = () => {

    return (
        <React.Fragment>
            <AuthHeaderContainer />
            <Outlet />
        </React.Fragment>
    )
}

export default LayoutComponent;