import React, { useEffect, useState } from 'react';
import 'react-toastify/dist/ReactToastify.css';
import AppRouting from './AppRouting';
import './core/axios.interceptor';
import * as  languageService from './service/common/LanguageService';
import { AppEnum } from './common/AppEnum';
// import { getNewToken } from './common/CommonMethods';
import { useNavigate } from 'react-router-dom';

const AppContainer = () => {

    const [isLanguageDataAvailable, setIsLanguageDataAvailable] = useState(false);
    const navigate = useNavigate();

    // sets several necessary states during component mounting
    useEffect(() => {

        // checkIfTokenIsExpired();
        let language = localStorage.getItem('language');
        if (!language)
            onchangeLanguageListHandler(AppEnum.LanguageListCD.Spanish);
        else
            onchangeLanguageListHandler(language);
    }, [])

    // It checks if the current time has exceeded the previous token expiry time or not
    // if it token is expired or about to expire then it fetches a new token and refresh token
    // const checkIfTokenIsExpired = () => {
    //     let refreshToken = localStorage.getItem('refresh_token');
    //     if (refreshToken) {

    //         let expires_in = localStorage.getItem('expires_in')
    //         let currentTime = new Date().getTime() / 1000;

    //         let allowAccessForNewToken = ((Number(expires_in) - 30) < currentTime ? true : false);

    //         if (allowAccessForNewToken)
    //             getNewToken();
    //         else {
    //             let totalTimeForRefreshInMs = (Number(expires_in) - currentTime - 30) * 1000;

    //             setTimeout(() => {
    //                 getNewToken();
    //             }, (totalTimeForRefreshInMs));
    //         }
    //     }
    // }



    // This method is used to change apps language via api
    const onchangeLanguageListHandler = (language) => {

        languageService.getLanguageLabels(language, AppEnum.LanguageApiUserInterfaceCD.WEB_FOOD_SELLER_APP)
            .then(res => {

                if (res) {
                    localStorage.setItem("language", language);

                    if (language === AppEnum.LanguageListCD.Spanish)
                        localStorage.setItem("languageCode", AppEnum.LanguageCodeCD.mexican);
                    else if (language === AppEnum.LanguageListCD.English)
                        localStorage.setItem("languageCode", AppEnum.LanguageCodeCD.us);

                    localStorage.setItem("languageApiLabels", JSON.stringify(res));
                    setIsLanguageDataAvailable(true);

                    let token = localStorage.getItem('token');
                    localStorage.removeItem('isTokenRefreshed');
                    if (token)
                        navigate('/seller/layout');
                }
            })
    }

    return (
        <div>
            {isLanguageDataAvailable && <AppRouting />}
        </div>
    )
}

export default AppContainer;