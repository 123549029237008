import { masterAxios } from "../../core/MasterAPIInterceptor";
import { axios } from "../../core/axios.interceptor";
import { tokenAxios } from "../../core/TokenAPIInterceptor";

// Now it is in master api (the language dropdown API)
export async function getlanguageDropdown() {
    return await masterAxios.get(`config/language`)
        .then((response) => {
            response.data.map((item) => {
                item.value = item.code;
                item.label = item.name
            })
            return response.data
        })
}

// it fetches active countries from api
export async function getActiveCountry(countryCode) {
    return await axios.get(`country?countryCode=${countryCode}`)
        .then((response) => {
            if (response) {
                let country = [];
                let activeCountry = {
                    value: response.data.uId,
                    label: response.data.code
                }
                country.push(activeCountry);
                return country
            }
        })
}

// validates credential
export async function validateLogin(mobileNo, role, languageCode) {
    return await tokenAxios.get(`user/validate-login/${mobileNo}/${role}/${languageCode}`)
        .then(response => {
            if (response)
                return response.data;
        })
}

// it fetches the state list according to the dial code
export async function fetchStateList(countryCode) {
    return await axios.get(`state/ddo?countryCode=${countryCode}`)
        .then((response) => {
            if (response)
                return response.data;
        })
}

// it fetches the city list according to the dial code
export async function fetchCityList(stateUId) {
    return await axios.get(`city/ddo?stateUId=${stateUId}`)
        .then((response) => {
            if (response)
                return response.data
        })
}

// it validates the partner if it already exists or not
export async function validatePartner(formData) {
    return await tokenAxios.post('user/validate-signup', formData)
        .then(response => {
            if (response)
                return response.data;
        })
}