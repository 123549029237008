import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Phase1Home from "../../component/phase-1-home/Phase1Home";

const Phase1HomeContainer = () => {
    const navigate = useNavigate()
    const [state, setState] = useState({ labelList: [] });

    // sets several necessary states during component mounting
    useEffect(() => {
        let masterApiData = JSON.parse(localStorage.getItem('languageApiLabels'));
        setState((prevState) => { return { ...prevState, labelList: masterApiData } });
        window.scrollTo(0, 0);
    }, [])

    // when all the documents are uploaded then it routes to the home page
    const onGoingNext = () => {
        navigate('/login')
    }

    return (
        <React.Fragment>
            <Phase1Home
                state={state}
                onGoingNext={onGoingNext}
            />
        </React.Fragment>
    )
}

export default Phase1HomeContainer;