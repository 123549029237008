import React from "react";
import './VerifyOtpModule.css';
import OtpField from 'react-otp-field';
import Header from "../common/header/Header";

const VerifyOtp = (props) => {

    const { labelList, verifyOTPState } = props.state;

    return (
        <React.Fragment>

            <Header />

            <div className='login-main-div'>

                <div className='otp-container'>

                    <h2 className="verify-otp-label">{labelList.filter(i => i.key === 'Login.VerifyOTP')[0]?.value}</h2>

                    <div>

                        <div className="otp-lbl-div">
                            <label>{labelList.filter(i => i.key === 'Login.EnterReceivedOtpLabel')[0]?.value}</label>
                            <label>&nbsp;{verifyOTPState.userName}</label>
                        </div>

                        <div className="form-group">

                            <div className="otp-input-div">
                                <OtpField
                                    value={verifyOTPState.mobileOtp}
                                    onChange={props.onChangeOtpHandler}
                                    numInputs={6}
                                    onChangeRegex={/^([0-9]{0,})$/}
                                    autoFocus
                                    type='tel'
                                    isTypeNumber={true}
                                    separator={<span>&nbsp;&nbsp;&nbsp;</span>}
                                />

                                <div className="resend-div">
                                    <label> {labelList.filter(i => i.key === 'Login.DidNotReceiveOtp')[0]?.value}&nbsp;</label>
                                    <label className="resend-label" onClick={props.onReSendOTP}> {labelList.filter(i => i.key === 'Login.Resend')[0]?.value}</label>
                                </div>

                            </div>

                            {props.state.isMobileOtpError && <span className="">{labelList.filter(i => i.key === 'Login.OtpRequiredErrorLabel')[0]?.value}</span>}
                        </div>

                    </div>

                    <div className={verifyOTPState.mobileOtp.length === 6 ? "verify-button-div" : "verify-button-div disable-btn"}>
                        <button onClick={props.onVerifyOTP}>{labelList.filter(i => i.key === 'Login.SendButton')[0]?.value}</button>
                    </div>
                    
                </div>
            </div>
        </React.Fragment>
    )
}

export default VerifyOtp;