import React, { useEffect, useRef, useState } from "react";
import personalState, { formValidationSchema } from './PersonalInfoState';
import * as commonService from '../../service/common/CommonService';
import * as commonMethods from '../../common/CommonMethods';
import * as  validateService from '../../service/auth/validateOTP/ValidateOTPService';
import Geocode from "react-geocode";
import PersonalInfo from "../../component/personal-data-(create-account)/PersonalInfo";
import { AppEnum } from "../../common/AppEnum";
import { useNavigate } from "react-router-dom";
import * as  loginService from '../../service/auth/login/Login.Service';

let countryCode = '';
let countryList = [];

const PersonalInfoContainer = () => {

    const isComponentMounted = useRef(true);
    const navigate = useNavigate()
    const [state, setState] = useState(personalState);
    const formikRef = useRef();

    // sets several necessary states during component mounting
    useEffect(() => {
        let masterApiData = JSON.parse(localStorage.getItem('languageApiLabels'));

        if (isComponentMounted.current) {
            setState((prevState) => { return { ...prevState, labelList: masterApiData } });

            getActiveCountryList();
        }
        window.scrollTo(0, 0);
        return () => {
            isComponentMounted.current = false;
        }
    }, [])

    // It fetches the state list according to the country code
    const getStateList = (countryCode) => {

        commonService.fetchStateList(countryCode).then(res => {
            if (res) {

                setState((prevState) => {
                    return {
                        ...prevState,
                        stateList: [
                            { value: '', label: prevState.labelList.filter(i => i.key === 'Phase1.State')[0]?.value },
                            ...res
                        ],
                    }
                })
            }
        })
    }

    //It fetches the city list according to the country uId
    const getCityList = (stateUId) => {

        commonService.fetchCityList(stateUId).then(res => {
            if (res) {

                setState((prevState) => {
                    return {
                        ...prevState,
                        cityList: [{ value: '', label: prevState.labelList.filter(i => i.key === 'Phase1.City')[0]?.value }, ...res],
                    }
                })
            }
        })
    }

    // Retrieves the country list and calls the method to get the current position
    const getActiveCountryList = () => {
        //It calls the method which extracts the current country name according to the location
        //Passes the lat long as the parameter to the method
        navigator.geolocation.getCurrentPosition(getCountryByLatLng,
            (error) => {
                // getCountryCode(null);

                // new country changes
                getActiveCountryCode('MX');
            })
    }

    // It calls the method which extracts the current country name according to the location
    // and sets the country code in state for the respective country 
    const getCountryByLatLng = (position) => {
        Geocode.setApiKey(AppEnum.GoogleApiKey.googleApiKey);

        Geocode.fromLatLng(position.coords.latitude, position.coords.longitude).then(
            (response) => {

                response.results.every((element) => {
                    let address = element?.address_components?.filter(item => {
                        if (item?.types?.includes("country")) {
                            return item;
                        }
                    });
                    if (address?.length) {
                        countryCode = address[0]?.short_name;
                        return false;
                    }
                    else
                        return true
                });
                // new country changes
                countryCode = !countryCode ? 'MX' : countryCode;
                getActiveCountryCode(countryCode);
            },
            (error) => {

                // new country changes
                getActiveCountryCode('MX');
            }
        );
    }

    // sets the retrieved active country code
    const getActiveCountryCode = (countryCode) => {

        getStateList(countryCode);

        commonService.getActiveCountry(countryCode).then(res => {
            if (res) {

                let masterApiData = JSON.parse(localStorage.getItem('languageApiLabels'));
                let mobileValidation = commonMethods.getMinMaxMobileLength(masterApiData, res[0]?.label);
                let validationSchema = formValidationSchema(masterApiData, mobileValidation.minMobileLength, mobileValidation.maxMobileLength);

                setState((prevState) => {
                    return {
                        ...prevState,
                        countryList: [...res], mobileNoMaxLength: mobileValidation.maxMobileLength,
                        countryCode: res[0].label, formValidationSchema: validationSchema
                    }
                });
            }
        })
    }

    // It sends the otp to the partner mobile number and routes to the otp page
    const createProfile = (personalInfo, otpDetail) => {

        validateService.sendOTP(otpDetail)
            .then(res => {

                if (res) {

                    personalInfo.otpuId = res.uId;
                    personalInfo.countryUId = state.countryList[0].value

                    if (formikRef.current)
                        resetFormikFieldState();

                    navigate('/verify-otp',
                        {
                            state: {
                                personalInfo: personalInfo,
                                otpDetail: otpDetail,
                            }
                        })
                }
            })
    }

    // it reinitialize the formik field states
    const resetFormikFieldState = () => {

        formikRef.current.setFieldValue("mobileNo", '');
        formikRef.current.setFieldValue("firstName", '');
        formikRef.current.setFieldValue("lastName", '');
        formikRef.current.setFieldValue("email", '');
        formikRef.current.setFieldValue("userPwd", '');
        formikRef.current.setFieldValue("confirmPassword", '');
        formikRef.current.setFieldValue("stateUId", '');
        formikRef.current.setFieldValue("cityUId", '');
    }

    // It checks the strength of the password and displays it
    const onchangePasswordHandler = (password) => {

        if (password.length >= 6) {

            let passwordStrength = commonMethods.checkPasswordStrength(password);
            setState((prevState) => {
                return {
                    ...prevState, passwordStrength: passwordStrength,
                    isPasswordStrength: true
                }
            })
        }
        else
            setState((prevState) => { return { ...prevState, passwordStrength: {}, isPasswordStrength: false } })
    }

    // it checks if email exists or not then renders the password fields 
    const onGoingNext = (personalInfo) => {

        let languageCode = localStorage.getItem('languageCode');
        let personalInfoFD = new FormData();

        personalInfoFD.append("mobileNo", state.countryCode + personalInfo.mobileNo);
        personalInfoFD.append("email", personalInfo.email);
        personalInfoFD.append("languageCode", languageCode);
        personalInfoFD.append("role", AppEnum.PartnerTypeCD.seller);

        // it validates the partner if it already exists or not
        loginService.validateEmailOrMobileNo(personalInfoFD)
            .then(res => {
                if (res) {
                    let otpDetail = {};

                    otpDetail.linkTableCD = AppEnum.LinkTableCD.Seller;
                    otpDetail.linkTableUId = '';
                    otpDetail.secureActionTypeCD = AppEnum.SecureActionTypeCD.seller;
                    otpDetail.languageCode = languageCode;
                    otpDetail.sendToMobile = true;
                    otpDetail.sendToEmail = false;
                    otpDetail.email = personalInfo.email;
                    otpDetail.mobileNo = state.countryCode + personalInfo.mobileNo;
                    personalInfo.mobileNo = state.countryCode + personalInfo.mobileNo;

                    createProfile(personalInfo, otpDetail);
                }
            })
    }

    // checks if the partner has accepted the agreement or not
    const onChangeAgreementHandler = () => {

        setState((prevState) => { return { ...prevState, isAgreementAccepted: !prevState.isAgreementAccepted } })
    }

    // route to the login page
    const routeToLogin = () => {

        navigate('/login')
    }

    return (
        <React.Fragment>
            <PersonalInfo
                state={state}
                onGoingNext={onGoingNext}
                onChangeAgreementHandler={onChangeAgreementHandler}
                onchangePasswordHandler={onchangePasswordHandler}
                getCityList={getCityList}
                routeToLogin={routeToLogin}
                formikRef={formikRef}
            />
        </React.Fragment>
    )
}

export default PersonalInfoContainer;