// Extracts the labels from local storage
const translationGettersApi = {
    es: JSON.parse(localStorage.getItem('languageApiLabels'))
};

// Returns the label according to the passed key, also if value is undefined for the key then
// the process will be skipped 
export function translateViaApi(key) {
    let label = '';
    if (translationGettersApi.es !== null) {
        if (translationGettersApi.es.filter(i => i.key === key)[0] === undefined) {
            //case: skip it when the label comes undefined
        }
        else
            label = translationGettersApi.es.filter(i => i.key === key)[0].value;
    }
    return label ? label : 'Missing translation';
}