import React from "react";
import { Formik, Field, Form } from 'formik';
import FormikInput from '../../component/common/formik/FormikInput';
import FormikSelect from '../../component/common/formik/FormikSelect';
import FormikCheckbox from '../../component/common/formik/FormikCheckbox';
import './PersonalInfoModule.css';
import * as commonMethods from '../../common/CommonMethods';
import Red from '../../asset/logos/Red.png';
import Yellow from '../../asset/logos/Yellow.png'
import Green from '../../asset/logos/Green.png'
import ReactTooltip from 'react-tooltip';
import { AppEnum } from "../../common/AppEnum";
import Header from "../common/header/Header";

const PersonalInfo = (props) => {

    const { labelList, stateList, personalInfo, countryList, formValidationSchema,
        cityList, mobileNoMaxLength } = props.state;

    return (
        <div className='personal-main-div'>
            <Formik
                initialValues={personalInfo}
                enableReinitialize='true'
                validationSchema={formValidationSchema}
                innerRef={props.formikRef}
                onSubmit={fields => {
                    props.onGoingNext(fields);
                }}
            >
                {(formik) => (
                    <React.Fragment >
                        <Header />
                        <div className='personal-container'>

                            <div>
                                <h2 className='login-title'>{labelList.filter(i => i.key === 'Phase1.CreateAccount')[0]?.value}</h2>
                                {
                                    props.state.remark &&
                                    <label className='rejected-remark'>{labelList.filter(i => i.key === 'Phase1.Remark')[0]?.value} : {props.state.remark}</label>
                                }

                                <Form noValidate>

                                    <div className="input-sub-div">
                                        <Field name="firstName" placeholder={labelList.filter(i => i.key === 'Phase1.Name')[0]?.value}
                                            maxLength='50' id="firstName" type="text" component={FormikInput} />
                                    </div>

                                    <div className="input-sub-div">
                                        <Field name="lastName" placeholder={labelList.filter(i => i.key === 'Phase1.LastName')[0]?.value}
                                            maxLength='50' id="lastName" type="text" component={FormikInput} />
                                    </div>

                                    <div className={`${'mobile-no-div'} ${props.state.countryCode && 'value-available'}`}>

                                        <Field value={props.state.countryCode} option={countryList} disabled={true}
                                            name='country' id='country' type='select' component={FormikSelect} />

                                        <span className='mobile-no-span'>
                                            <Field maxLength={mobileNoMaxLength} name='mobileNo' placeholder={labelList.filter(i => i.key === 'Login.MobileNumberLabel')[0]?.value}
                                                disabled={props.state.remark ? true : false} id='mobileNo' type='tel' onKeyDown={commonMethods.updateNumber} component={FormikInput} />
                                        </span>
                                    </div>

                                    <div className="input-sub-div">
                                        <Field name="email" placeholder={labelList.filter(i => i.key === 'Login.EmailLabel')[0]?.value}
                                            disabled={props.state.remark ? true : false} id="email" type="text" component={FormikInput} />
                                    </div>

                                    {
                                        !props.state.remark &&
                                        <div>

                                            <div className={props.state.isPasswordStrength ? "pass-m-div pass-div" : "pass-div"}>
                                                <Field name="userPwd" id="userPwd" placeholder={labelList.filter(i => i.key === 'Login.PasswordLabel')[0]?.value}
                                                    disabled={props.state.remark ? true : false} type="password" onChange={(event) => props.onchangePasswordHandler(event)} component={FormikInput} />
                                                <i className="fa fa-eye" onClick={(event) => commonMethods.showHidePasswordLogin(event, 'userPwd')}></i>
                                            </div>

                                            {
                                                props.state.isPasswordStrength &&
                                                <span className={props.state.passwordStrength.className}>
                                                    {
                                                        props.state.passwordStrength.strength === "Excellent" ?

                                                            <div className='pass-strength-bar'>

                                                                <div className='pass-tooltip-div'>
                                                                    {labelList.filter(i => i.key === 'Validation.PasswordStrengthExcellent')[0].value}
                                                                    <i className="fas fa-info-circle pass-tooltip-div" data-tip data-for='excellentPassword' ></i>
                                                                    <ReactTooltip className='tooltip-width' id='excellentPassword' aria-haspopup='true' type='success' place="top" multiline={true} effect="float">
                                                                        {labelList.filter(i => i.key === 'Validation.PasswordStrengthExcellentToolTip')[0].value}
                                                                    </ReactTooltip>
                                                                </div>

                                                                <img src={Green} alt="Excellent Password" width="100%" />
                                                            </div>
                                                            :
                                                            props.state.passwordStrength.strength === "Medium" ?

                                                                <div className='pass-strength-bar'>

                                                                    <div className='pass-tooltip-div'>
                                                                        {labelList.filter(i => i.key === 'Validation.PasswordStrengthMedium')[0].value}
                                                                        <i className="fas fa-info-circle pass-tooltip-div" data-tip data-for='mediumPassword' ></i>
                                                                        <ReactTooltip className='tooltip-width' id='mediumPassword' aria-haspopup='true' type='info' place="top" multiline={true} effect="float">
                                                                            {labelList.filter(i => i.key === 'Validation.PasswordStrengthMediumToolTip')[0].value}
                                                                        </ReactTooltip>
                                                                    </div>

                                                                    <img src={Yellow} alt="Medium Password" width="100%" />
                                                                </div>
                                                                :
                                                                <div className='pass-strength-bar'>

                                                                    <div className='pass-tooltip-div'>
                                                                        {labelList.filter(i => i.key === 'Validation.PasswordStrengthLow')[0].value}
                                                                        <i className="fas fa-info-circle pass-tooltip-div" data-tip data-for='lowPassword' ></i>
                                                                        <ReactTooltip className='tooltip-width' id='lowPassword' aria-haspopup='true' type='error' place="top" multiline={true} effect="float">
                                                                            {labelList.filter(i => i.key === 'Validation.PasswordStrengthLowToolTip')[0].value}
                                                                        </ReactTooltip>
                                                                    </div>

                                                                    <img src={Red} alt="Low Password" width="100%" />
                                                                </div>
                                                    }
                                                </span>
                                            }
                                            <div className="confirm-pass-div">

                                                <Field name="confirmPassword" placeholder={labelList.filter(i => i.key === 'Phase1.ConfirmPasswordLabel')[0]?.value}
                                                    disabled={props.state.remark ? true : false} id="confirmPassword" type="password" component={FormikInput} />
                                                <i className="fa fa-eye" onClick={(event) => commonMethods.showHidePasswordLogin(event, 'confirmPassword')}></i>

                                            </div>
                                        </div>
                                    }
                                    <div className='form-group'>

                                        <div className={`${'state-div'} ${formik.values.stateUId && 'value-available'}`}>
                                            <Field name="stateUId" id="stateUId" type="select" onChange={props.getCityList} option={stateList}
                                                component={FormikSelect} />
                                        </div>

                                    </div>
                                    <div className='form-group'>

                                        <div className={`${'city-div'} ${formik.values.cityUId && 'value-available'}`}>
                                            <Field name="cityUId" id="cityUId" type="select" option={cityList} onChange={() => { }}
                                                component={FormikSelect} />
                                        </div>

                                    </div>
                                    <div className='t-and-c-div'>

                                        <Field name="isActive" id="acceptAgreement" type="checkbox" onChange={props.onChangeAgreementHandler} value={props.state.isAgreementAccepted} component={FormikCheckbox} />

                                        <label>{labelList.filter(i => i.key === "Phase1.PassAccept")[0]?.value}
                                            <a href={localStorage.getItem('languageCode') === AppEnum.LanguageCodeCD.us ?
                                                `${AppEnum.AnchorLinks.TAndCEnglish}` : `${AppEnum.AnchorLinks.TAndCSpanish}`} target="_blank">{labelList.filter(i => i.key === "Phase1.TermsAndCondtions")[0]?.value}</a>
                                            {labelList.filter(i => i.key === "Phase1.TheLabel")[0]?.value}
                                            <a href={localStorage.getItem('languageCode') === AppEnum.LanguageCodeCD.us ?
                                                `${AppEnum.AnchorLinks.PolicyEnglish}` : `${AppEnum.AnchorLinks.PolicySpanish}`} target="_blank">{labelList.filter(i => i.key === "Phase1.PrivacyPolicy")[0]?.value}</a>
                                            {labelList.filter(i => i.key === "Phase1.Treatment")[0]?.value}
                                        </label>
                                    </div>

                                    <div className={(props.state.remark ? (formik.isValid && props.state.isAgreementAccepted) :
                                        (formik.isValid && formik.dirty && props.state.isAgreementAccepted)) ? 'submit-btn-div pad-btm' : 'submit-btn-div disable-btn pad-btm'} >
                                        <button type='submit'>{labelList.filter(i => i.key === 'Login.SignUpButton')[0]?.value}</button>
                                    </div>

                                    <div className="already-account-div">
                                        {labelList.filter(i => i.key === 'Phase1.HaveAccount')[0]?.value}&nbsp;
                                        <label onClick={() => props.routeToLogin()}>{labelList.filter(i => i.key === 'Phase1.Login')[0]?.value}</label>
                                    </div>
                                </Form>
                            </div>
                        </div>
                    </React.Fragment >
                )}
            </Formik >
        </div>
    )
}

export default PersonalInfo;