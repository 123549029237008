import React, { useEffect, useRef, useState } from "react";
import states from './VerifyOTPState';
import * as  validateService from '../../service/auth/validateOTP/ValidateOTPService';
import { useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import * as  loginService from '../../service/auth/login/Login.Service';
import * as  signupService from '../../service/auth/Signup.Service';
import VerifyOtp from "../../component/verify-otp/VerifyOtp";
import { AppEnum } from "../../common/AppEnum";
import { getNewToken } from "../../common/CommonMethods";

const VerifyOtpContainer = () => {

    const isComponentMounted = useRef(true);
    const navigate = useNavigate();
    const location = useLocation();
    const [state, setState] = useState(states);

    // sets several necessary states during component mounting
    useEffect(() => {
        let masterApiData = JSON.parse(localStorage.getItem('languageApiLabels'));
        let { verifyOTPState } = state;
        verifyOTPState.otp = '';
        verifyOTPState.mobileOtp = '';
        if (location.state.otpDetail) {
            verifyOTPState.userName = location.state.otpDetail?.mobileNo;
        }
        if (isComponentMounted.current) {
            setState((prevState) => {
                return {
                    ...prevState,
                    labelList: masterApiData, verifyOTPState: verifyOTPState
                }
            });
        }
        window.scrollTo(0, 0);
        return () => {
            isComponentMounted.current = false;
        }
    }, [])

    // Sets the otp in state
    const onChangeOtpHandler = (otp) => {
        if (otp.length >= 0) {
            const { verifyOTPState } = state;
            verifyOTPState.mobileOtp = otp;
            setState((prevState) => { return { ...prevState, verifyOTPState: verifyOTPState } });
        }
        if (otp.length < 6) {
            const { verifyOTPState } = state;
            verifyOTPState.isMobileOtpError = true;
            setState((prevState) => { return { ...prevState, verifyOTPState: verifyOTPState } });
        }
        if (otp.length === 6) {
            const { verifyOTPState } = state;
            verifyOTPState.isMobileOtpError = false;
            setState((prevState) => { return { ...prevState, verifyOTPState: verifyOTPState } });
        }
    }

    // It resends the otp to the mobile number and sets the new otpUId
    const onResendDriverOTP = () => {

        validateService.sendOTP(location.state.otpDetail).then(res => {
            if (res) {

                if (location.state.personalInfo)
                    location.state.personalInfo.otpuId = res.uId;

                let toaster = state.labelList.filter(i => i.key === 'Validation.OtpSentSuccessfully')[0]?.value;
                toast.success(toaster);
            }
        })
    }

    // Sets the otp state to empty string
    const onReSendOTP = () => {

        const { verifyOTPState } = state;
        verifyOTPState.mobileOtp = '';
        verifyOTPState.isVerify = true;
        verifyOTPState.isMobileOtpError = false;

        setState((prevState) => { return { ...prevState, verifyOTPState: verifyOTPState, } });
        onResendDriverOTP();
    }

    // It verifies the otp, If otp is valid it proceeds else it shows the error
    const onVerifyOTP = () => {

        const { verifyOTPState } = state;
        let verifyOTPData = verifyOTPState;
        verifyOTPState.isMobileOtpError = false;

        if (verifyOTPData.mobileOtp.length === 0 || verifyOTPData.mobileOtp.length < 6) {
            verifyOTPState.isMobileOtpError = false;
            setState((prevState) => { return { ...prevState, verifyOTPState: verifyOTPState } });
        }
        else if (verifyOTPData.mobileOtp.length === 6) {
            verifyOTPData.otp = verifyOTPData.mobileOtp;
            validate(verifyOTPData);
        }
    }

    // It creates a form data object then calls the login API, If token is received in response
    // then it redirects to the respective page
    const login = async () => {

        let formData = new FormData();
        let languageCode = localStorage.getItem('languageCode');

        formData.append('client_secret', AppEnum.FormDataMobileCD.client_secret);
        formData.append('grant_type', AppEnum.FormDataMobileCD.grant_type);
        formData.append('client_id', AppEnum.FormDataMobileCD.client_id);
        formData.append('otp_uid', location.state.otpDetail.otpuId);
        formData.append('sms_otp', state.verifyOTPState.mobileOtp);
        formData.append('languagecode', languageCode);

        loginService.login(formData)
            .then(res => {

                if (res.access_token) {
                    // getNewTokenBeforeExpiration(res, false);
                    localStorage.setItem('refresh_token', res.refresh_token);
                    localStorage.setItem('token', res.access_token);
                    navigate('/seller/layout');
                    // navigate('/seller/phase-2');
                }
            })
    }

    // It validates the loginPartnerType, if it is undefined then it completes the first registration step else
    // It proceeds for login 
    const validate = () => {

        if (location.state.loginPartnerType === undefined) {

            let languageCode = localStorage.getItem('languageCode');

            var token = localStorage.getItem('token');
            if (token)
                localStorage.removeItem('token');

            let sellerDetail = location.state.personalInfo;
            delete sellerDetail.isActive
            sellerDetail.languageCode = languageCode;
            sellerDetail.otp = state.verifyOTPState.mobileOtp;
            sellerDetail.emailOTP = state.verifyOTPState.mobileOtp;
            sellerDetail.timeZone = new Date().getTimezoneOffset();
            sellerDetail.address = '';
            sellerDetail.zipCode = '';
            sellerDetail.cuisineUIDs = '';
            sellerDetail.businessName = '';
            sellerDetail.businessDescription = '';

            signupService.registerSeller(sellerDetail).then(
                res => {

                    if (res.accessToken) {
                        localStorage.setItem('refresh_token', res.refreshToken);
                        localStorage.setItem('token', res.accessToken);
                        // getNewTokenBeforeExpiration(res, true);
                        // navigate('/restaurant-info');
                        navigate('/seller/layout');
                    }
                })
        }
        if (location.state.loginPartnerType === AppEnum.LoginCD.seller) {
            login();
        }
    }

    // it fetches a new token when token is about to expire
    // const getNewTokenBeforeExpiration = (res, isNewSeller) => {

    //     let currentTime = new Date().getTime() / 1000 + res.expires_in;
    //     localStorage.setItem('expires_in', currentTime);

    //     localStorage.setItem('token', isNewSeller ? res.accessToken : res.access_token);
    //     localStorage.setItem('refresh_token', isNewSeller ? res.refreshToken : res.refresh_token);

    //     setTimeout(() => {
    //         getNewToken();
    //     }, (res.expires_in - 30) * 1000);
    // }

    return (
        <React.Fragment>
            <VerifyOtp
                onVerifyOTP={onVerifyOTP}
                onChangeOtpHandler={onChangeOtpHandler}
                onReSendOTP={onReSendOTP}
                state={state}
            />
        </React.Fragment>
    )
}

export default VerifyOtpContainer;